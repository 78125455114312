export const mobilVariant = {
    initial: {
        opacity: 0,
        padding: 0,
        margin: 0,
        width: '100%',
        backgroundColor: '#F3f5f9'
    },
    animate: {
        opacity: 1,
        width: '100%',
        margin: 0,
        transition: { duration: .3 }
    },
    exit: {
        opacity: 0,
        transition: { duration: .3 }
    }
}

export const mobilMenuVariant = {
    initial: {
        opacity: 0,
        padding: 0,
        marginLeft: -300,
        height: '100vh',
        width: '100%',
    },
    animate: {
        position: 'fixed',
        top: 0,
        left: 0,
        padding: 10,
        backgroundColor: 'white',
        opacity: 1,
        width: '100%',
        overflow: 'auto',
        marginLeft: 0,
        height: '100vh',
        transition: { duration: .3 }
    },
    exit: {
        opacity: 0,
        marginLeft: -300,
        left: -500,
        position: 'fixed',
        top: 0,
        width: '100%',
        height: '100vh',
        transition: { duration: .3 }
    }
}