import React, { useEffect, useState } from 'react';
import { Edit2, File, Home, List, PlusCircle, Trash2 } from 'react-feather';
import { Button, Card, CardBody, CardHeader, Col, Input, Row, Spinner } from 'reactstrap';
import { motion } from 'framer-motion';
import { Popconfirm, Table, Button as Button2, Tooltip } from 'antd';
import { toast } from 'react-toastify';
import Header from '../../../components/header';
import { createDocument, deleteDocument, getAllDocumentsByGroup, getDocument, updateDocument } from '../../../core/request';
import { mobilVariant } from '../../../components/animation';
import { useNavigate, useParams } from 'react-router-dom';
import { randomColor } from '../../../components/general';
import { Helmet } from 'react-helmet';

const DocumentsPage = () => {
    const { id } = useParams()
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false)
    const [formData, setFormData] = useState({ id: null, file: null, filePath: "", Title: "", TitleEN: "", DocumentGroupID: id })

    const clearForm = () => {
        setFormData({ id: null, file: null, filePath: "", Title: "", TitleEN: "", DocumentGroupID: id })
    }

    const getDatas = async () => {
        const documents = await getAllDocumentsByGroup(id);
        if (documents.message === "Success")
            setData(documents.result);

        setTimeout(() => {
            setLoading(true)
        }, 1000);
    }

    const getData = async (id) => {
        const data = await getDocument(id);
        setFormData({ id: id, filePath: data.result.fileFullPath, Title: data.result.title, TitleEN: data.result.titleEN, DocumentGroupID: data.result.DocumentGroupID })
    }

    const addData = async () => {
        let res;
        if (formData.id) {
            res = await updateDocument(formData.id, formData.file, formData.Title, formData.TitleEN, id);
        } else {
            res = await createDocument(formData.file, formData.Title, formData.TitleEN, id);
        }
        if (res.message === "Success")
            toast.success("İşlem başarılı.")
        else
            toast.error("İşlem sırasında bir sorun oluştu!")
        clearForm()
        getDatas()
    }

    const deleteData = async (id) => {
        const form = await deleteDocument(id);
        if (form.message === "Success")
            toast.success("İşlem başarılı.")
        else
            toast.error("İşlem sırasında bir sorun oluştu!")
        clearForm()
        getDatas()
    }

    const columns = [
        {
            title: "",
            key: "documentID",
            render: (e) => <div className='table-logo shadow' style={{ backgroundColor: randomColor() }}>{e.title[0]}</div>,
            width: 50
        },
        {
            title: "ID",
            width: 60,
            key: "documentID",
            dataIndex: "documentID"
        },
        {
            title: "Başlık",
            key: "title",
            render: (e) => e.title
        },
        {
            title: "İşlemler",
            render: (e) => (
                <div className='d-flex justify-content-start'>
                    <div className='ml-2'>
                        <Tooltip title="Düzenle">
                            <Button2 className='border-warning text-warning' icon={<Edit2 size={20} className='text-warning' />} onClick={() => getData(e.documentID)} />
                        </Tooltip>
                    </div>
                    <div className='ml-2'>
                        <Popconfirm
                            title="Bu içeriği silmek istediğinizden emin misiniz?"
                            onConfirm={() => deleteData(e.documentID)}
                            okText="Evet"
                            cancelText="Hayır"
                        >
                            <Tooltip title="Sil">
                                <Button2 icon={<Trash2 size={20} className='text-danger' />} danger />
                            </Tooltip>
                        </Popconfirm>
                    </div>
                </div>
            )
        }
    ];

    useEffect(() => {
        getDatas();
    }, []);

    return (
        <motion.div {...mobilVariant}>
            <Helmet>
                <title>Harvest We - Döküman Yönetimi</title>
            </Helmet>
            <Row className='w-100 pb-5'>
                <Col sm={3} md={3} xl={3}>
                    <Header />
                </Col>
                <Col sm={9} md={9} xl={9}>
                    <div className='right-content mb-5'>
                        <div className='d-flex justify-content-between'>
                            <div><h3>Döküman Yönetimi</h3></div>
                            <div className='d-flex justify-content-end'>
                                <Button onClick={() => navigate("/slider-group")} className='ml-2 bg-white border border-info text-info'><List size={20} style={{ marginTop: -5 }} /> Döküman Grupları</Button>
                                <Button onClick={() => navigate("/")} className='ml-2 bg-white border border-info text-info'><Home size={20} style={{ marginTop: -5 }} /> Dashboard</Button>
                            </div>
                        </div>
                        <Card className='shadow mt-3'>
                            <CardHeader className='pl-4 shadow-sm d-flex justify-content-between align-items-center bg-white p-3' tag='h4'>Döküman {formData.id ? "Güncelle" : "Ekle"}</CardHeader>
                            <CardBody>
                                <Row className='p-1'>
                                    <Col md={12} className='mb-3'>
                                        <div>
                                            {formData.file || formData.filePath ? <div className={formData.file ? 'd-flex mb-4 justify-content-center' : 'd-flex mb-1 justify-content-center'}>
                                                <div><div className='w-100 text-center mb-2'><File size={25} /></div><div>{formData.file ? formData.file.name : null}</div></div>
                                            </div> : <div className='d-flex justify-content-center'>
                                                <div className='p-2 d-flex align-items-center justify-content-center rounded border border-secondary mb-2' style={{ width: 150, height: 60 }}>Döküman Yok!</div>
                                            </div>}
                                        </div>
                                    </Col>
                                    <Col md={4} className='mb-3'>
                                        <span>Döküman</span>
                                        <Input type='file' onChange={e => setFormData({ ...formData, file: e.target.files[0] })} />
                                    </Col>
                                    <Col md={4} className='mb-3'>
                                        <span>Başlık</span>
                                        <Input type='input' value={formData.Title} onChange={e => setFormData({ ...formData, Title: e.target.value })} />
                                    </Col>
                                    <Col md={4} className='mb-3'>
                                        <span>Başlık (EN)</span>
                                        <Input type='input' value={formData.TitleEN} onChange={e => setFormData({ ...formData, TitleEN: e.target.value })} />
                                    </Col>
                                    {/* <Col md={6} className='mb-3'>
                                        <span>Url</span>
                                        <Input type='input' value={formData.URL} onChange={e => setFormData({ ...formData, URL: e.target.value })} />
                                    </Col>
                                    <Col md={6} className='mb-3'>
                                        <span>Url (EN)</span>
                                        <Input type='input' value={formData.URLEN} onChange={e => setFormData({ ...formData, URLEN: e.target.value })} />
                                    </Col>
                                    <Col md={6} className='mb-3'>
                                        <span>Açıklama</span>
                                        <Input type='input' value={formData.Description} onChange={e => setFormData({ ...formData, Description: e.target.value })} />
                                    </Col>
                                    <Col md={6} className='mb-3'>
                                        <span>Açıklama (EN)</span>
                                        <Input type='input' value={formData.DescriptionEN} onChange={e => setFormData({ ...formData, DescriptionEN: e.target.value })} />
                                    </Col> */}
                                    <Col md={12} className='mt-3 d-flex justify-content-end'>
                                        {formData.id ? <Button onClick={() => clearForm()} className='bg-white border-warning text-warning'>Temizle</Button> : null}
                                        <Button
                                            color={formData.id ? 'warning' : 'success'}
                                            onClick={() => addData()}
                                        >
                                            <PlusCircle size={20} style={{ marginTop: -3, marginRight: 7 }} />{formData.id ? "Güncelle" : "Ekle"}
                                        </Button>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        <Card className='shadow mt-4'>
                            <CardHeader className='pl-4 d-flex shadow-sm justify-content-between align-items-center bg-white p-3'>
                                <div><h4>Döküman Listesi</h4></div>
                            </CardHeader>
                            <CardBody>
                                {loading ? <Table
                                    rowKey='documentID'
                                    dataSource={data}
                                    columns={columns}
                                    scroll={{ x: 'max-content' }}
                                /> : <div className='w-100 mt-5 mb-5 d-flex justify-content-center align-items-center'>
                                    <Spinner style={{ width: '3rem', height: '3rem' }} color='info'> </Spinner>
                                </div>}
                            </CardBody>
                        </Card>
                    </div>
                </Col>
            </Row>
        </motion.div>
    );
};

export default DocumentsPage;
