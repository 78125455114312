import React, { useEffect, useState } from 'react';
import { Edit2, Home, List, PlusCircle, Trash2 } from 'react-feather';
import { Button, Card, CardBody, CardHeader, Col, Input, Row, Spinner } from 'reactstrap';
import { motion } from 'framer-motion';
import { Popconfirm, Table, Button as Button2, Tooltip } from 'antd';
import { toast } from 'react-toastify';
import Header from '../../../components/header';
import { createSocial, deleteSocial, getAllSocialsBySocial, getSocial, updateSocial } from '../../../core/request';
import { mobilVariant } from '../../../components/animation';
import { useNavigate, useParams } from 'react-router-dom';
import { randomColor } from '../../../components/general';
import { Helmet } from 'react-helmet';
import { url2 } from '../../../api';

const SocialsPage = () => {
    const { id } = useParams()
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false)
    const [formData, setFormData] = useState({ id: null, file: null, filePath: "", title: "", url: "", socialID: id })

    const clearForm = () => {
        setFormData({ id: null, file: null, filePath: "", title: "", url: "", socialID: id })
    }

    const getDatas = async () => {
        const socials = await getAllSocialsBySocial(id);
        if (socials.message === "Success")
            setData(socials.result);

        setTimeout(() => {
            setLoading(true)
        }, 1000);
    }

    const getData = async (id) => {
        const data = await getSocial(id);
        setFormData({ id: id, filePath: data.result.fileFullPath, title: data.result.title, url: data.result.url })
    }

    const addData = async () => {
        let res;
        if (formData.id) {
            res = await updateSocial(formData.id, formData.file, formData.title, formData.url, id);
        } else {
            res = await createSocial(formData.file, formData.title, formData.url, id,);
        }
        if (res.message === "Success")
            toast.success("İşlem başarılı.")
        else
            toast.error("İşlem sırasında bir sorun oluştu!")
        clearForm()
        getDatas()
    }

    const deleteData = async (id) => {
        const form = await deleteSocial(id);
        if (form.message === "Success")
            toast.success("İşlem başarılı.")
        else
            toast.error("İşlem sırasında bir sorun oluştu!")
        clearForm()
        getDatas()
    }

    const columns = [
        {
            title: "",
            key: "socialID",
            render: (e) => e.fileFullPath ? <div><img src={`${url2}${e.fileFullPath}`} alt='' style={{ height: 36, width: 36, borderRadius: 18 }} /></div> : <div className='table-logo shadow' style={{ backgroundColor: randomColor() }}>{e.title ? e.title[0] : null}</div>,
            width: 50
        },
        {
            title: "ID",
            key: "socialID",
            dataIndex: "socialID"
        },
        {
            title: "Başlık",
            key: "title",
            render: (e) => e.title
        },
        {
            title: "URL",
            key: "URL",
            render: (e) => e.url
        },
        {
            title: "İşlemler",
            render: (e) => (
                <div className='d-flex justify-content-start'>
                    <div className='ml-2'>
                        <Tooltip title="Düzenle">
                            <Button2 className='border-warning text-warning' icon={<Edit2 size={20} className='text-warning' />} onClick={() => getData(e.socialID)} />
                        </Tooltip>
                    </div>
                    <div className='ml-2'>
                        <Popconfirm
                            title="Bu içeriği silmek istediğinizden emin misiniz?"
                            onConfirm={() => deleteData(e.socialID)}
                            okText="Evet"
                            cancelText="Hayır"
                        >
                            <Tooltip title="Sil">
                                <Button2 icon={<Trash2 size={20} className='text-danger' />} danger />
                            </Tooltip>
                        </Popconfirm>
                    </div>
                </div>
            )
        }
    ];

    useEffect(() => {
        getDatas();
    }, []);

    return (
        <motion.div {...mobilVariant}>
            <Helmet>
                <title>Harvest We - Sosyal Medya Yönetimi</title>
            </Helmet>
            <Row className='w-100 pb-5'>
                <Col sm={3} md={3} xl={3}>
                    <Header />
                </Col>
                <Col sm={9} md={9} xl={9}>
                    <div className='right-content mb-5'>
                        <div className='d-flex justify-content-between'>
                            <div><h3>Sosyal Medya Yönetimi</h3></div>
                            <div className='d-flex justify-content-end'>
                                <Button onClick={() => navigate("/social-media")} className='ml-2 bg-white border border-info text-info'><List size={20} style={{ marginTop: -5 }} /> Sosyal Medyalar</Button>
                                <Button onClick={() => navigate("/")} className='ml-2 bg-white border border-info text-info'><Home size={20} style={{ marginTop: -5 }} /> Dashboard</Button>
                            </div>
                        </div>
                        <Card className='shadow mt-3'>
                            <CardHeader className='pl-4 shadow-sm d-flex justify-content-between align-items-center bg-white p-3' tag='h4'>Sosyal Medya Ekle</CardHeader>
                            <CardBody>
                                <Row className='p-1'>
                                    <Col md={12} className='mb-3'>
                                        <div>
                                            {formData.file || formData.filePath ? <div className={formData.file ? 'd-flex mb-4 justify-content-center' : 'd-flex mb-1 justify-content-center'}>
                                                <img src={formData.file ? URL.createObjectURL(formData.file) : `${url2}${formData.filePath}`} alt='Resim ' style={{ height: 60, width: 100, objectFit: 'cover', borderRadius: 10 }} />
                                            </div> : <div className='d-flex justify-content-center'>
                                                <div className='p-2 d-flex align-items-center justify-content-center rounded border border-secondary mb-2' style={{ width: 150, height: 60 }}>Logo Yok!</div>
                                            </div>}
                                        </div>
                                    </Col>
                                    <Col md={4} className='mb-3'>
                                        <span>Logo</span>
                                        <Input type='file' onChange={e => setFormData({ ...formData, file: e.target.files[0] })} />
                                    </Col>
                                    <Col md={4} className='mb-3'>
                                        <span>Sosyal Medya</span>
                                        <Input type='input' value={formData.title} onChange={e => setFormData({ ...formData, title: e.target.value })} />
                                    </Col>
                                    <Col md={4} className='mb-3'>
                                        <span>URL</span>
                                        <Input type='input' value={formData.url} onChange={e => setFormData({ ...formData, url: e.target.value })} />
                                    </Col>
                                    <Col md={12} className='mt-3 d-flex justify-content-end'>
                                        {formData.id ? <Button onClick={() => clearForm()} className='bg-white border-warning text-warning'>Temizle</Button> : null}
                                        <Button
                                            color={formData.id ? 'warning' : 'success'}
                                            onClick={() => addData()}
                                        >
                                            <PlusCircle size={20} style={{ marginTop: -3, marginRight: 7 }} />{formData.id ? "Güncelle" : "Ekle"}
                                        </Button>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        <Card className='shadow mt-4'>
                            <CardHeader className='pl-4 d-flex shadow-sm justify-content-between align-items-center bg-white p-3'>
                                <div><h4>Sosyal Medya Listesi</h4></div>
                            </CardHeader>
                            <CardBody>
                                {loading ? <Table
                                    rowKey='socialID'
                                    dataSource={data}
                                    columns={columns}
                                    scroll={{ x: 'max-content' }}
                                /> : <div className='w-100 mt-5 mb-5 d-flex justify-content-center align-items-center'>
                                    <Spinner style={{ width: '3rem', height: '3rem' }} color='info'> </Spinner>
                                </div>}
                            </CardBody>
                        </Card>
                    </div>
                </Col>
            </Row>
        </motion.div>
    );
};

export default SocialsPage;
