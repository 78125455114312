import React, { useEffect, useState } from 'react';
import { Edit2, Home, List, PlusCircle, Trash2 } from 'react-feather';
import { Button, Card, CardBody, CardHeader, Col, Input, Row, Spinner } from 'reactstrap';
import { motion } from 'framer-motion';
import { Popconfirm, Table, Button as Button2, Tooltip, Select } from 'antd';
import { toast } from 'react-toastify';
import Header from '../../../components/header';
import { createSlider, deleteSlider, getAllHeaders, getAllSlidersByGroup, getSlider, updateSlider } from '../../../core/request';
import { mobilVariant } from '../../../components/animation';
import { useNavigate, useParams } from 'react-router-dom';
import { randomColor } from '../../../components/general';
import { Helmet } from 'react-helmet';
import { url2 } from '../../../api';
import { CKEditor } from 'ckeditor4-react';

const SlidersPage = () => {
    const { id } = useParams()
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [content, setContent] = useState(null)
    const [contentEn, setContentEn] = useState(null)
    const [headers, setHeaders] = useState([]);
    const [loading, setLoading] = useState(false)
    const [formData, setFormData] = useState({ id: null, file: null, filePath: "", Title: "", TitleEN: "", Description: "", DescriptionEN: "", URL: "", URLEN: "", AdditionalField1: "", AdditionalField1EN: "", AdditionalField2: null, AdditionalField2EN: "", AdditionalField3: "", AdditionalField3EN: "", SliderGroupID: id })

    const clearForm = () => {
        setContent(null)
        setContentEn(null)
        setFormData({ id: null, file: null, filePath: "", Title: "", TitleEN: "", Description: "", DescriptionEN: "", URL: "", URLEN: "", AdditionalField1: "", AdditionalField1EN: "", AdditionalField2: null, AdditionalField2EN: "", AdditionalField3: "", AdditionalField3EN: "", SliderGroupID: id })
    }

    const getDatas = async () => {
        const sliders = await getAllSlidersByGroup(id);
        const categories = await getAllHeaders();
        if (categories.message === "Success") {
            var catList = []
            var catList2 = []
            for (var i = 0; i < categories.result.length; i++) {
                catList.push({ label: categories.result[i].title, value: categories.result[i].headerID })
                catList2.push(categories.result[i])
                if (categories.result[i].subHeaders !== undefined && categories.result[i].subHeaders.length > 0) {
                    for (var j = 0; j < categories.result[i].subHeaders.length; j++) {
                        catList.push({ label: categories.result[i].subHeaders[j].title, value: categories.result[i].subHeaders[j].headerID })
                        catList2.push(categories.result[i].subHeaders[j])
                    }
                }
            }
            setHeaders(catList)
        }

        if (sliders.message === "Success")
            setData(sliders.result);

        setTimeout(() => {
            setLoading(true)
        }, 1000);
    }

    const getData = async (id) => {
        const data = await getSlider(id);
        setContent(data.result.description)
        setContentEn(data.result.descriptionEN)
        setFormData({ id: id, filePath: data.result.fileFullPath, Title: data.result.title, TitleEN: data.result.titleEN, Description: data.result.description, DescriptionEN: data.result.descriptionEN, URL: data.result.url, URLEN: data.result.urlen, AdditionalField1: data.result.additionalField1, AdditionalField1EN: data.result.additionalField1EN, AdditionalField2: parseInt(data.result.additionalField2), AdditionalField2EN: data.result.additionalField2EN, AdditionalField3: data.result.additionalField3, AdditionalField3EN: data.result.additionalField3EN, SliderGroupID: id })
    }

    const handleEditorChange = (event) => {
        var data = event.editor.getData()
        setContent(data)
    };

    const handleEditorChangeEN = (event) => {
        var data = event.editor.getData()
        setContentEn(data)
    };

    const addData = async () => {
        let res;
        if (formData.id) {
            res = await updateSlider(formData.id, formData.file, formData.Title, formData.TitleEN, content, contentEn, formData.URL, formData.URLEN, formData.AdditionalField1, formData.AdditionalField1EN, parseInt(formData.AdditionalField2), formData.AdditionalField2EN, formData.AdditionalField3, formData.AdditionalField3EN, id);
        } else {
            res = await createSlider(formData.file, formData.Title, formData.TitleEN, content, contentEn, formData.URL, formData.URLEN, formData.AdditionalField1, formData.AdditionalField1EN, parseInt(formData.AdditionalField2), formData.AdditionalField2EN, formData.AdditionalField3, formData.AdditionalField3EN, id);
        }
        if (res.message === "Success")
            toast.success("İşlem başarılı.")
        else
            toast.error("İşlem sırasında bir sorun oluştu!")
        clearForm()
        getDatas()
    }

    const deleteData = async (id) => {
        const form = await deleteSlider(id);
        if (form.message === "Success")
            toast.success("İşlem başarılı.")
        else
            toast.error("İşlem sırasında bir sorun oluştu!")
        clearForm()
        getDatas()
    }

    const columns = [
        {
            title: "",
            key: "sliderID",
            render: (e) => e.fileFullPath ? <div><img src={`${url2}${e.fileFullPath}`} alt='' style={{ height: 36, width: 36, borderRadius: 18 }} /></div> : <div className='table-logo shadow' style={{ backgroundColor: randomColor() }}>{e.title[0]}</div>,
            width: 50
        },
        {
            title: "ID",
            key: "sliderID",
            dataIndex: "sliderID"
        },
        {
            title: "Başlık",
            key: "title",
            render: (e) => e.title
        },
        {
            title: "İşlemler",
            render: (e) => (
                <div className='d-flex justify-content-start'>
                    <div className='ml-2'>
                        <Tooltip title="Düzenle">
                            <Button2 className='border-warning text-warning' icon={<Edit2 size={20} className='text-warning' />} onClick={() => getData(e.sliderID)} />
                        </Tooltip>
                    </div>
                    <div className='ml-2'>
                        <Popconfirm
                            title="Bu içeriği silmek istediğinizden emin misiniz?"
                            onConfirm={() => deleteData(e.sliderID)}
                            okText="Evet"
                            cancelText="Hayır"
                        >
                            <Tooltip title="Sil">
                                <Button2 icon={<Trash2 size={20} className='text-danger' />} danger />
                            </Tooltip>
                        </Popconfirm>
                    </div>
                </div>
            )
        }
    ];

    useEffect(() => {
        getDatas();
    }, []);

    return (
        <motion.div {...mobilVariant}>
            <Helmet>
                <title>Harvest We - Slider Yönetimi</title>
            </Helmet>
            <Row className='w-100 pb-5'>
                <Col sm={3} md={3} xl={3}>
                    <Header />
                </Col>
                <Col sm={9} md={9} xl={9}>
                    <div className='right-content mb-5'>
                        <div className='d-flex justify-content-between'>
                            <div><h3>Slider Yönetimi</h3></div>
                            <div className='d-flex justify-content-end'>
                                <Button onClick={() => navigate("/slider-group")} className='ml-2 bg-white border border-info text-info'><List size={20} style={{ marginTop: -5 }} /> Slider Grupları</Button>
                                <Button onClick={() => navigate("/")} className='ml-2 bg-white border border-info text-info'><Home size={20} style={{ marginTop: -5 }} /> Dashboard</Button>
                            </div>
                        </div>
                        <Card className='shadow mt-3'>
                            <CardHeader className='pl-4 shadow-sm d-flex justify-content-between align-items-center bg-white p-3' tag='h4'>Slider Ekle</CardHeader>
                            <CardBody>
                                <Row className='p-1'>
                                    <Col md={12} className='mb-3'>
                                        <div>
                                            {formData.file || formData.filePath ? <div className={formData.file ? 'd-flex mb-4 justify-content-center' : 'd-flex mb-1 justify-content-center'}>
                                                <img src={formData.file ? URL.createObjectURL(formData.file) : `${url2}${formData.filePath}`} alt='Resim ' style={{ height: 60, width: 100, objectFit: 'cover', borderRadius: 10 }} />
                                            </div> : <div className='d-flex justify-content-center'>
                                                <div className='p-2 d-flex align-items-center justify-content-center rounded border border-secondary mb-2' style={{ width: 150, height: 60 }}>Resim Yok!</div>
                                            </div>}
                                        </div>
                                    </Col>
                                    <Col md={4} className='mb-3'>
                                        <span>Resim</span>
                                        <Input type='file' onChange={e => setFormData({ ...formData, file: e.target.files[0] })} />
                                    </Col>
                                    <Col md={4} className='mb-3'>
                                        <span>Başlık</span>
                                        <Input type='input' value={formData.Title} onChange={e => setFormData({ ...formData, Title: e.target.value })} />
                                    </Col>
                                    <Col md={4} className='mb-3'>
                                        <span>Başlık (EN)</span>
                                        <Input type='input' value={formData.TitleEN} onChange={e => setFormData({ ...formData, TitleEN: e.target.value })} />
                                    </Col>
                                    {/* <Col md={4} className='mb-3'>
                                        <span>Url</span>
                                        <Input type='input' value={formData.URL} onChange={e => setFormData({ ...formData, URL: e.target.value })} />
                                    </Col>
                                    <Col md={4} className='mb-3'>
                                        <span>Url (EN)</span>
                                        <Input type='input' value={formData.URLEN} onChange={e => setFormData({ ...formData, URLEN: e.target.value })} />
                                    </Col> */}
                                    <Col md={4} className='mb-3'>
                                        <span>URL</span>
                                        <Select
                                            options={headers}
                                            className='w-100'
                                            style={{ height: 38 }}
                                            value={formData.AdditionalField2}
                                            onChange={e => setFormData({ ...formData, AdditionalField2: e })}
                                        />
                                    </Col>
                                    <Col md={4} className='mb-3'>
                                        <span>Buton İsmi</span>
                                        <Input type='input' value={formData.AdditionalField1} onChange={e => setFormData({ ...formData, AdditionalField1: e.target.value })} />
                                    </Col>
                                    <Col md={4} className='mb-3'>
                                        <span>Buton İsmi (EN)</span>
                                        <Input type='input' value={formData.AdditionalField1EN} onChange={e => setFormData({ ...formData, AdditionalField1EN: e.target.value })} />
                                    </Col>
                                    <Col md={12} className='mb-3'>
                                        <span>İçerik</span>
                                        <CKEditor
                                            key={formData.id === 0 ? null : formData.id}
                                            editorUrl="https://cdn.ckeditor.com/4.16.0/standard/ckeditor.js"
                                            data={content}
                                            initData={content}
                                            onChange={handleEditorChange}
                                            onInstanceReady={(event) => {
                                                const editor = event.editor;
                                                editor.on('mode', () => {
                                                    if (editor.mode === 'source') {
                                                        const intervalId = setInterval(() => {
                                                            const data = editor.getData();
                                                            setContent(data)
                                                        }, 1000);

                                                        editor.on('mode', () => {
                                                            if (editor.mode !== 'source') {
                                                                clearInterval(intervalId);
                                                            }
                                                        });
                                                    }
                                                });
                                            }}
                                            config={{
                                                toolbar: [
                                                    ['Source', '-', 'Save', 'NewPage', 'Preview', 'Print', '-', 'Templates'],
                                                    ['Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', '-', 'Undo', 'Redo'],
                                                    ['Find', 'Replace', '-', 'SelectAll', '-', 'Scayt'],
                                                    ['Form', 'Checkbox', 'Radio', 'TextField', 'Textarea', 'Select', 'Button', 'ImageButton', 'HiddenField'],
                                                    '/',
                                                    ['Bold', 'Italic', 'Underline', 'Strike', '-', 'TextColor', 'BGColor'],
                                                    ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote', 'CreateDiv'],
                                                    ['JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock'],
                                                    ['BidiLtr', 'BidiRtl'],
                                                    ['Link', 'Unlink', 'Anchor'],
                                                    ['Image', 'Flash', 'Table', 'HorizontalRule', 'Smiley', 'SpecialChar', 'PageBreak', 'Iframe'],
                                                    '/',
                                                    ['Styles', 'Format', 'Font', 'FontSize'],
                                                    ['Maximize', 'ShowBlocks'],
                                                    ['About']
                                                ],
                                                extraPlugins: 'colorbutton,font,justify,bidi,forms,div,find,flash,iframe,pagebreak,smiley,scayt',
                                                removePlugins: 'exportpdf',
                                                allowedContent: true
                                            }}
                                        />
                                    </Col>
                                    <Col md={12} className='mb-3'>
                                        <span>İçerik EN</span>
                                        <CKEditor
                                            key={formData.id === 0 ? null : formData.id}
                                            editorUrl="https://cdn.ckeditor.com/4.16.0/standard/ckeditor.js"
                                            data={contentEn}
                                            initData={contentEn}
                                            onChange={handleEditorChangeEN}
                                            onInstanceReady={(event) => {
                                                const editor = event.editor;
                                                editor.on('mode', () => {
                                                    if (editor.mode === 'source') {
                                                        const intervalId = setInterval(() => {
                                                            const data = editor.getData();
                                                            setContentEn(data)
                                                        }, 1000);

                                                        editor.on('mode', () => {
                                                            if (editor.mode !== 'source') {
                                                                clearInterval(intervalId);
                                                            }
                                                        });
                                                    }
                                                });
                                            }}
                                            config={{
                                                toolbar: [
                                                    ['Source', '-', 'Save', 'NewPage', 'Preview', 'Print', '-', 'Templates'],
                                                    ['Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', '-', 'Undo', 'Redo'],
                                                    ['Find', 'Replace', '-', 'SelectAll', '-', 'Scayt'],
                                                    ['Form', 'Checkbox', 'Radio', 'TextField', 'Textarea', 'Select', 'Button', 'ImageButton', 'HiddenField'],
                                                    '/',
                                                    ['Bold', 'Italic', 'Underline', 'Strike', '-', 'TextColor', 'BGColor'],
                                                    ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote', 'CreateDiv'],
                                                    ['JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock'],
                                                    ['BidiLtr', 'BidiRtl'],
                                                    ['Link', 'Unlink', 'Anchor'],
                                                    ['Image', 'Flash', 'Table', 'HorizontalRule', 'Smiley', 'SpecialChar', 'PageBreak', 'Iframe'],
                                                    '/',
                                                    ['Styles', 'Format', 'Font', 'FontSize'],
                                                    ['Maximize', 'ShowBlocks'],
                                                    ['About']
                                                ],
                                                extraPlugins: 'colorbutton,font,justify,bidi,forms,div,find,flash,iframe,pagebreak,smiley,scayt',
                                                removePlugins: 'exportpdf',
                                                allowedContent: true
                                            }}
                                        />
                                    </Col>
                                    <Col md={12} className='mt-3 d-flex justify-content-end'>
                                        {formData.id ? <Button onClick={() => clearForm()} className='bg-white border-warning text-warning'>Temizle</Button> : null}
                                        <Button
                                            color={formData.id ? 'warning' : 'success'}
                                            onClick={() => addData()}
                                        >
                                            <PlusCircle size={20} style={{ marginTop: -3, marginRight: 7 }} />{formData.id ? "Güncelle" : "Ekle"}
                                        </Button>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        <Card className='shadow mt-4'>
                            <CardHeader className='pl-4 d-flex shadow-sm justify-content-between align-items-center bg-white p-3'>
                                <div><h4>Slider Listesi</h4></div>
                            </CardHeader>
                            <CardBody>
                                {loading ? <Table
                                    rowKey='sliderID'
                                    dataSource={data}
                                    columns={columns}
                                    scroll={{ x: 'max-content' }}
                                /> : <div className='w-100 mt-5 mb-5 d-flex justify-content-center align-items-center'>
                                    <Spinner style={{ width: '3rem', height: '3rem' }} color='info'> </Spinner>
                                </div>}
                            </CardBody>
                        </Card>
                    </div>
                </Col>
            </Row>
        </motion.div>
    );
};

export default SlidersPage;
