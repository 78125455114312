import React, { useState } from 'react'
import { Menu, Home, User, Globe, LogOut, Aperture, MessageCircle, Smartphone, Image, Grid, List, Users, Bell, X, PlayCircle, MapPin, Instagram, AlignJustify, File, Square } from 'react-feather'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import logo from '../image/logo.png'
import { motion, AnimatePresence } from 'framer-motion';
import { mobilMenuVariant } from './animation'
import { activeUser } from '../core/request'
import { Button, ButtonGroup, Modal, ModalBody, ModalHeader } from 'reactstrap'

const Header = () => {
    const [openMobil, setOpenMobil] = useState(false)
    const [modal, setModal] = useState(false)

    const logouts = async () => {
        await activeUser()
        localStorage.clear();
        toast.success('Çıkış Başarılı.')
        setTimeout(() => {
            window.location.href = '/login'
        }, 1000)
    }

    return (
        <>
            <div className='navbarheader overflow-auto shadow p-3 mb-5'>
                <div className='logo my-4'>
                    <div><img src={logo} alt='' style={{ width: '80%', filter: 'drop-shadow(2px 4px 6px black)' }} /></div>
                </div>
                <div className='down'>
                    <div className='baslik'><small>Menüler</small></div>
                    <div className='menus'>
                        <div className={window.location.pathname.includes("header") ? "enable-menu" : ""}><Link to='/header'><AlignJustify /><span>Menü Yönetimi</span></Link></div>
                        <div className={window.location.pathname.includes("form") ? "enable-menu" : ""}><Link to='/form'><MessageCircle /><span>Form Yönetimi</span></Link></div>
                        <div className={window.location.pathname.includes("document-group") ? "enable-menu" : ""}><Link to='/document-group'><File /><span>Döküman Yönetimi</span></Link></div>
                        <div className={window.location.pathname.includes("page") ? "enable-menu" : ""}><Link to='/page'><Square /><span>Sayfa Yönetimi</span></Link></div>
                        <div className={window.location.pathname.includes("contact") ? "enable-menu" : ""}><Link to='/contact'><MapPin /><span>İletişim Yönetimi</span></Link></div>
                        <div className={window.location.pathname.includes("product-group") ? "enable-menu" : ""}><Link to='/product-group'><Grid /><span>Ürün Yönetimi</span></Link></div>
                        <div className={window.location.pathname.includes("seo") ? "enable-menu" : ""}><Link to='/seo'><Globe /><span>Seo Yönetimi</span></Link></div>
                        <div className={window.location.pathname.includes("slider-group") ? "enable-menu" : ""}><Link to='/slider-group'><List /><span  >Slider Yönetimi</span></Link></div>
                        <div className={window.location.pathname.includes("social-media") ? "enable-menu" : ""}><Link to='/social-media'><Instagram /><span>Sosyal Medya</span></Link></div>
                        <div className={window.location.pathname.includes("user") ? "enable-menu" : ""}><Link to='/user'><Users /><span>Kullanıcı Yönetimi</span></Link></div>
                    </div>
                    <div className='baslik'><small>Ayarlar</small></div>
                    <div className='menus border-0'>
                        <div className={window.location.pathname.includes("setting") ? "enable-menu" : ""}><Link to='/setting'><User /><span>Ayarlar</span></Link></div>
                        <div><a target='_blank' href='https://harvestwe.com/'><Globe /><span>Siteye Git</span></a></div>
                        <div><a onClick={() => setModal(!modal)}><LogOut /><span>Çıkış Yap</span></a></div>
                    </div>
                </div>
                <div className='template text-center mb-4'>
                    <div><span>Tüm Hakları Saklıdır.</span></div>
                    <div><a href='https://www.liberyus.com/'>Liberyus</a></div>
                </div>
            </div>

            <div className='mobil-menu'>
                <div><Link to='/' className='text-white'><h2>Harvest We</h2></Link></div>
                <div onClick={() => { setOpenMobil(!openMobil) }}><Menu color='white' /></div>
            </div>

            <AnimatePresence>
                {
                    openMobil ? <motion.div {...mobilMenuVariant} className='mobil-menu-view'>
                        <div className='logo'>
                            <div><img src={logo} alt='' style={{ width: '100%', filter: 'invert(1)' }} /></div>
                            <div onClick={() => { setOpenMobil(!openMobil) }}><X color='white' /></div>
                        </div>
                        <div className='down'>
                            <div className='baslik'><small>Menüler</small></div>
                            <div className='menus'>
                                <div className={window.location.pathname.includes("header") ? "enable-menu" : ""}><Link to='/header'><AlignJustify /><span>Menü Yönetimi</span></Link></div>
                                <div className={window.location.pathname.includes("form") ? "enable-menu" : ""}><Link to='/form'><MessageCircle /><span>Form Yönetimi</span></Link></div>
                                <div className={window.location.pathname.includes("document-group") ? "enable-menu" : ""}><Link to='/document-group'><File /><span>Döküman Yönetimi</span></Link></div>
                                <div className={window.location.pathname.includes("page") ? "enable-menu" : ""}><Link to='/page'><Square /><span>Sayfa Yönetimi</span></Link></div>
                                <div className={window.location.pathname.includes("contact") ? "enable-menu" : ""}><Link to='/contact'><MapPin /><span>İletişim Yönetimi</span></Link></div>
                                <div className={window.location.pathname.includes("product-group") ? "enable-menu" : ""}><Link to='/product-group'><Grid /><span>Ürün Yönetimi</span></Link></div>
                                <div className={window.location.pathname.includes("seo") ? "enable-menu" : ""}><Link to='/seo'><Globe /><span>Seo Yönetimi</span></Link></div>
                                <div className={window.location.pathname.includes("slider-group") ? "enable-menu" : ""}><Link to='/slider-group'><List /><span  >Slider Yönetimi</span></Link></div>
                                <div className={window.location.pathname.includes("social-media") ? "enable-menu" : ""}><Link to='/social-media'><Instagram /><span>Sosyal Medya</span></Link></div>
                                <div className={window.location.pathname.includes("user") ? "enable-menu" : ""}><Link to='/user'><Users /><span>Kullanıcı Yönetimi</span></Link></div>
                            </div>
                            <div className='baslik'><small>Ayarlar</small></div>
                            <div className='menus border-0'>
                                <div className={window.location.pathname.includes("setting") ? "enable-menu" : ""}><Link to='/setting'><User /><span>Ayarlar</span></Link></div>
                                <div><a target='_blank' href='https://harvestwe.com/'><Globe /><span>Siteye Git</span></a></div>
                                <div><a onClick={() => setModal(!modal)}><LogOut /><span>Çıkış Yap</span></a></div>
                            </div>
                            <div className='template text-center mb-4'>
                                <div><span>Tüm Hakları Saklıdır.</span></div>
                                <div><a href='https://www.liberyus.com/'>Liberyus</a></div>
                            </div>
                        </div>
                    </motion.div> : null
                }
            </AnimatePresence>

            <Modal isOpen={modal} toggle={() => setModal(!modal)} className='modal-dialog-centered'>
                <ModalHeader className='border-0' toggle={() => setModal(!modal)}></ModalHeader>
                <ModalBody>
                    <div className='p-3 text-center'>
                        <div><h3>Çıkış Yap</h3></div>
                        <div className='mt-3'><span>Çıkış yapmak istediğinize emin misiniz?</span></div>
                        <div className='mt-4 d-flex justify-content-center'>
                            <ButtonGroup>
                                <Button className='bg-transparent px-4 border-danger text-danger' onClick={() => setModal(!modal)}>Hayır</Button>
                                <Button className='bg-danger border-0 px-4 text-white' onClick={logouts}>Evet</Button>
                            </ButtonGroup>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </>
    )
}

export default Header