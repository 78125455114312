import React, { useEffect, useState } from 'react';
import { Home, PlusCircle } from 'react-feather';
import { Button, Card, CardBody, CardHeader, Col, Input, Row, Spinner } from 'reactstrap';
import { motion } from 'framer-motion';
import { toast } from 'react-toastify';
import Header from '../../components/header';
import { getAllSeos, getAllSocialMedias, getSetting, updateSetting } from '../../core/request';
import { mobilVariant } from '../../components/animation';
import { Helmet } from 'react-helmet';
import { url2 } from '../../api';
import { CKEditor } from 'ckeditor4-react';
import { Select } from 'antd';

const SettingPage = () => {
    const [loading, setLoading] = useState(false)
    const [seos, setSeos] = useState([])
    const [socialMedias, setSocialMedias] = useState([])

    const [formData, setFormData] = useState({ id: 1, file: null, filePath: "", file2: null, filePath2: "", file3: null, filePath3: "", file4: null, filePath4: "", file5: null, filePath5: "", file6: null, filePath6: "", file7: null, filePath7: "", file8: null, filePath8: "", FooterText: "", FooterTextEN: "", Kvk: "", KvkEN: "", AdditionalField1: "", AdditionalField1EN: "", AdditionalField2: "", AdditionalField2EN: "", AdditionalField3: "", AdditionalField3EN: "", AdditionalField4: "", AdditionalField4EN: "", AdditionalField5: "", AdditionalField5EN: "", AdditionalField6: "", AdditionalField6EN: "", AdditionalField7: "", AdditionalField7EN: "", AdditionalField8: "", AdditionalField8EN: "", AdditionalField9: "", AdditionalField9EN: "", AdditionalField10: "", AdditionalField10EN: "", HomeSeoID: null, ContactSeoID: null, SocialMediaDarkID: null, SocialMediaWhiteID: null, Phone1: "", Phone2: "", Phone3: "", Mail1: "", Mail2: "", Mail3: "", Fax1: "", Fax2: "", Fax3: "" });

    const getData = async () => {
        const setting = await getSetting();
        const seos = await getAllSeos();
        const socialMedia = await getAllSocialMedias();

        if (seos.message === "Success") {
            var newlist = []
            for (var i = 0; i < seos.result.length; i++) {
                if (seos.result[i] !== undefined)
                    newlist.push({ value: seos.result[i].seoID, label: seos.result[i].title });
            }
            setSeos(newlist);
        }

        if (socialMedia.message === "Success") {
            var newlist = []
            for (var i = 0; i < socialMedia.result.length; i++) {
                if (socialMedia.result[i] !== undefined)
                    newlist.push({ value: socialMedia.result[i].socialMediaID, label: socialMedia.result[i].title });
            }
            setSocialMedias(newlist);
        }

        setFormData({
            filePath: setting.result.fileFullPath,
            filePath2: setting.result.fileFullPath2,
            filePath3: setting.result.fileFullPath3,
            filePath4: setting.result.fileFullPath4,
            filePath5: setting.result.fileFullPath5,
            filePath6: setting.result.fileFullPath6,
            filePath7: setting.result.fileFullPath7,
            filePath8: setting.result.fileFullPath8,
            FooterText: setting.result.footerText,
            FooterTextEN: setting.result.footerTextEN,
            Kvk: setting.result.kvk,
            KvkEN: setting.result.kvkEN,
            AdditionalField1: setting.result.additionalField1,
            AdditionalField1EN: setting.result.additionalField1EN,
            AdditionalField2: setting.result.additionalField2,
            AdditionalField2EN: setting.result.additionalField2EN,
            AdditionalField3: setting.result.additionalField3,
            AdditionalField3EN: setting.result.additionalField3EN,
            AdditionalField4: setting.result.additionalField4,
            AdditionalField4EN: setting.result.additionalField4EN,
            AdditionalField5: setting.result.additionalField5,
            AdditionalField5EN: setting.result.additionalField5EN,
            AdditionalField6: setting.result.additionalField6,
            AdditionalField6EN: setting.result.additionalField6EN,
            AdditionalField7: setting.result.additionalField7,
            AdditionalField7EN: setting.result.additionalField7EN,
            AdditionalField8: setting.result.additionalField8,
            AdditionalField8EN: setting.result.additionalField8EN,
            AdditionalField9: setting.result.additionalField9,
            AdditionalField9EN: setting.result.additionalField9EN,
            AdditionalField10: setting.result.additionalField10,
            AdditionalField10EN: setting.result.additionalField10EN,
            HomeSeoID: setting.result.homeSeoID,
            ContactSeoID: setting.result.contactSeoID,
            SocialMediaDarkID: setting.result.socialMediaDarkID,
            SocialMediaWhiteID: setting.result.socialMediaWhiteID,
            Phone1: setting.result.phone1,
            Phone2: setting.result.phone2,
            Phone3: setting.result.phone3,
            Mail1: setting.result.mail1,
            Mail2: setting.result.mail2,
            Mail3: setting.result.mail3,
            Fax1: setting.result.fax1,
            Fax2: setting.result.fax2,
            Fax3: setting.result.fax3
        })

        setTimeout(() => {
            setLoading(true)
        }, 1000);
    }

    const handleEditorChange = (event) => {
        setFormData({ ...formData, Kvk: event.editor.getData() })
    };

    const handleEditorChangeEN = (event) => {
        setFormData({ ...formData, KvkEN: event.editor.getData() })
    };

    const editData = async () => {
        var res = await updateSetting(1, formData.file, formData.file2, formData.file3, formData.file4, formData.file5, formData.file6, formData.file7, formData.file8, formData.FooterText, formData.FooterTextEN, formData.Kvk, formData.KvkEN, formData.AdditionalField1, formData.AdditionalField1EN, formData.AdditionalField2, formData.AdditionalField2EN, formData.AdditionalField3, formData.AdditionalField3EN, formData.AdditionalField4, formData.AdditionalField4EN, formData.AdditionalField5, formData.AdditionalField5EN, formData.AdditionalField6, formData.AdditionalField6EN, formData.AdditionalField7, formData.AdditionalField7EN, formData.AdditionalField8, formData.AdditionalField8EN, formData.AdditionalField9, formData.AdditionalField9EN, formData.AdditionalField10, formData.AdditionalField10EN, formData.HomeSeoID, formData.ContactSeoID, formData.SocialMediaDarkID, formData.SocialMediaWhiteID, formData.Phone1, formData.Phone2, formData.Phone3, formData.Mail1, formData.Mail2, formData.Mail3, formData.Fax1, formData.Fax2, formData.Fax3);
        if (res.message === "Success")
            toast.success("İşlem başarılı.")
        else
            toast.error("İşlem sırasında bir sorun oluştu!")
        getData()
    }

    useEffect(() => {
        getData();
    }, []);

    return (
        <motion.div {...mobilVariant}>
            <Helmet>
                <title>Harvest We - Ayarlar</title>
            </Helmet>
            <Row className='w-100 pb-5'>
                <Col sm={3} md={3} xl={3}>
                    <Header />
                </Col>
                <Col sm={9} md={9} xl={9}>
                    <div className='right-content mb-5'>
                        <div className='d-flex justify-content-between'>
                            <div><h3>Genel Ayarlar</h3></div>
                            <div className='d-flex justify-content-end'>
                                <Button className='ml-2 bg-white border border-info text-info'><Home size={20} style={{ marginTop: -5 }} /> Dashboard</Button>
                            </div>
                        </div>
                        <Card className='shadow mt-3'>
                            <CardHeader className='pl-4 shadow-sm d-flex justify-content-between align-items-center bg-white p-3' tag='h4'>Harvest We Ayarları</CardHeader>
                            <CardBody>
                                {loading ? <Row className='p-1'>
                                    <Col md={4} className='mb-3'>
                                        <div className='border-s rounded p-2'>
                                            {formData.file || formData.filePath ? <div className={formData.file ? 'd-flex mb-4 justify-content-center' : 'd-flex mb-1 justify-content-center'}>
                                                <img src={formData.file ? URL.createObjectURL(formData.file) : `${url2}${formData.filePath}`} alt='Resim ' style={{ height: 60, width: 100, objectFit: 'contain', borderRadius: 10 }} />
                                            </div> : <div className='d-flex justify-content-center'>
                                                <div className='p-2 d-flex align-items-center justify-content-center rounded border border-secondary mb-2' style={{ width: 150, height: 60 }}>Resim Yok!</div>
                                            </div>}
                                            <span>Header Logo</span>
                                            <Input type='file' onChange={e => setFormData({ ...formData, file: e.target.files[0] })} />
                                        </div>
                                    </Col>
                                    <Col md={4} className='mb-3'>
                                        <div className='border-s rounded p-2'>
                                            {formData.file2 || formData.filePath2 ? <div className={formData.file2 ? 'd-flex mb-4 justify-content-center' : 'd-flex mb-1 justify-content-center'}>
                                                <img src={formData.file2 ? URL.createObjectURL(formData.file2) : `${url2}${formData.filePath2}`} alt='Resim ' style={{ height: 60, width: 100, objectFit: 'contain', borderRadius: 10 }} />
                                            </div> : <div className='d-flex justify-content-center'>
                                                <div className='p-2 d-flex align-items-center justify-content-center rounded border border-secondary mb-2' style={{ width: 150, height: 60 }}>Resim Yok!</div>
                                            </div>}
                                            <span>Footer Logo</span>
                                            <Input type='file' onChange={e => setFormData({ ...formData, file2: e.target.files[0] })} />
                                        </div>
                                    </Col>
                                    <Col md={4} className='mb-3'>
                                        <div className='border-s rounded p-2'>
                                            {formData.file3 || formData.filePath3 ? <div className={formData.file3 ? 'd-flex mb-4 justify-content-center' : 'd-flex mb-1 justify-content-center'}>
                                                <img src={formData.file3 ? URL.createObjectURL(formData.file3) : `${url2}${formData.filePath3}`} alt='Resim ' style={{ height: 60, width: 100, objectFit: 'contain', borderRadius: 10 }} />
                                            </div> : <div className='d-flex justify-content-center'>
                                                <div className='p-2 d-flex align-items-center justify-content-center rounded border border-secondary mb-2' style={{ width: 150, height: 60 }}>Resim Yok!</div>
                                            </div>}
                                            <span>Ürün Slider Logo</span>
                                            <Input type='file' onChange={e => setFormData({ ...formData, file3: e.target.files[0] })} />
                                        </div>
                                    </Col>
                                    <Col md={4} className='mb-3'>
                                        <div className='border-s rounded p-2'>
                                            {formData.file4 || formData.filePath4 ? <div className={formData.file4 ? 'd-flex mb-4 justify-content-center' : 'd-flex mb-1 justify-content-center'}>
                                                <img src={formData.file4 ? URL.createObjectURL(formData.file4) : `${url2}${formData.filePath4}`} alt='Resim ' style={{ height: 60, width: 100, objectFit: 'contain', borderRadius: 10 }} />
                                            </div> : <div className='d-flex justify-content-center'>
                                                <div className='p-2 d-flex align-items-center justify-content-center rounded border border-secondary mb-2' style={{ width: 150, height: 60 }}>Resim Yok!</div>
                                            </div>}
                                            <span>Ana Sayfa Ürün Logo 1</span>
                                            <Input type='file' onChange={e => setFormData({ ...formData, file4: e.target.files[0] })} />
                                        </div>
                                    </Col>
                                    <Col md={4} className='mb-3'>
                                        <div className='border-s rounded p-2'>
                                            {formData.file5 || formData.filePath5 ? <div className={formData.file5 ? 'd-flex mb-4 justify-content-center' : 'd-flex mb-1 justify-content-center'}>
                                                <img src={formData.file5 ? URL.createObjectURL(formData.file5) : `${url2}${formData.filePath5}`} alt='Resim ' style={{ height: 60, width: 100, objectFit: 'contain', borderRadius: 10 }} />
                                            </div> : <div className='d-flex justify-content-center'>
                                                <div className='p-2 d-flex align-items-center justify-content-center rounded border border-secondary mb-2' style={{ width: 150, height: 60 }}>Resim Yok!</div>
                                            </div>}
                                            <span>Ana Sayfa Ürün Logo 1 (EN)</span>
                                            <Input type='file' onChange={e => setFormData({ ...formData, file5: e.target.files[0] })} />
                                        </div>
                                    </Col>
                                    <Col md={4} className='mb-3'>
                                        <div className='border-s rounded p-2'>
                                            {formData.file6 || formData.filePath6 ? <div className={formData.file6 ? 'd-flex mb-4 justify-content-center' : 'd-flex mb-1 justify-content-center'}>
                                                <img src={formData.file6 ? URL.createObjectURL(formData.file6) : `${url2}${formData.filePath6}`} alt='Resim ' style={{ height: 60, width: 100, objectFit: 'contain', borderRadius: 10 }} />
                                            </div> : <div className='d-flex justify-content-center'>
                                                <div className='p-2 d-flex align-items-center justify-content-center rounded border border-secondary mb-2' style={{ width: 150, height: 60 }}>Resim Yok!</div>
                                            </div>}
                                            <span>Ana Sayfa Ürün Logo 2</span>
                                            <Input type='file' onChange={e => setFormData({ ...formData, file6: e.target.files[0] })} />
                                        </div>
                                    </Col>
                                    <Col md={4} className='mb-3'>
                                        <div className='border-s rounded p-2'>
                                            {formData.file7 || formData.filePath7 ? <div className={formData.file7 ? 'd-flex mb-4 justify-content-center' : 'd-flex mb-1 justify-content-center'}>
                                                <img src={formData.file7 ? URL.createObjectURL(formData.file7) : `${url2}${formData.filePath7}`} alt='Resim ' style={{ height: 60, width: 100, objectFit: 'contain', borderRadius: 10 }} />
                                            </div> : <div className='d-flex justify-content-center'>
                                                <div className='p-2 d-flex align-items-center justify-content-center rounded border border-secondary mb-2' style={{ width: 150, height: 60 }}>Resim Yok!</div>
                                            </div>}
                                            <span>İletişim Banner</span>
                                            <Input type='file' onChange={e => setFormData({ ...formData, file7: e.target.files[0] })} />
                                        </div>
                                    </Col>
                                    <Col md={4} className='mb-3'>
                                        <div className='border-s rounded p-2'>
                                            {formData.file8 || formData.filePath8 ? <div className={formData.file8 ? 'd-flex mb-4 justify-content-center' : 'd-flex mb-1 justify-content-center'}>
                                                <img src={formData.file8 ? URL.createObjectURL(formData.file8) : `${url2}${formData.filePath8}`} alt='Resim ' style={{ height: 60, width: 100, objectFit: 'contain', borderRadius: 10 }} />
                                            </div> : <div className='d-flex justify-content-center'>
                                                <div className='p-2 d-flex align-items-center justify-content-center rounded border border-secondary mb-2' style={{ width: 150, height: 60 }}>Resim Yok!</div>
                                            </div>}
                                            <span>Ek Logo</span>
                                            <Input type='file' onChange={e => setFormData({ ...formData, file8: e.target.files[0] })} />
                                        </div>
                                    </Col>
                                    <Col md={6} className='mb-3'>
                                        <span>Footer Metni</span>
                                        <Input type='textarea' value={formData.FooterText} onChange={e => setFormData({ ...formData, FooterText: e.target.value })} />
                                    </Col>
                                    <Col md={6} className='mb-3'>
                                        <span>Footer Metni (EN)</span>
                                        <Input type='textarea' value={formData.FooterTextEN} onChange={e => setFormData({ ...formData, FooterTextEN: e.target.value })} />
                                    </Col>
                                    <Col md={3} className='mb-3'>
                                        <span>Ek Alan 1</span>
                                        <Input type='input' value={formData.AdditionalField1} onChange={e => setFormData({ ...formData, AdditionalField1: e.target.value })} />
                                    </Col>
                                    <Col md={3} className='mb-3'>
                                        <span>Ek Alan 1 (EN)</span>
                                        <Input type='input' value={formData.AdditionalField1EN} onChange={e => setFormData({ ...formData, AdditionalField1EN: e.target.value })} />
                                    </Col>
                                    <Col md={3} className='mb-3'>
                                        <span>Ek Alan 2</span>
                                        <Input type='input' value={formData.AdditionalField2} onChange={e => setFormData({ ...formData, AdditionalField2: e.target.value })} />
                                    </Col>
                                    <Col md={3} className='mb-3'>
                                        <span>Ek Alan 2 (EN)</span>
                                        <Input type='input' value={formData.AdditionalField2EN} onChange={e => setFormData({ ...formData, AdditionalField2EN: e.target.value })} />
                                    </Col>
                                    <Col md={3} className='mb-3'>
                                        <span>Ek Alan 3</span>
                                        <Input type='input' value={formData.AdditionalField3} onChange={e => setFormData({ ...formData, AdditionalField3: e.target.value })} />
                                    </Col>
                                    <Col md={3} className='mb-3'>
                                        <span>Ek Alan 3 (EN)</span>
                                        <Input type='input' value={formData.AdditionalField3EN} onChange={e => setFormData({ ...formData, AdditionalField3EN: e.target.value })} />
                                    </Col>
                                    <Col md={3} className='mb-3'>
                                        <span>Ek Alan 4</span>
                                        <Input type='input' value={formData.AdditionalField4} onChange={e => setFormData({ ...formData, AdditionalField4: e.target.value })} />
                                    </Col>
                                    <Col md={3} className='mb-3'>
                                        <span>Ek Alan 4 (EN)</span>
                                        <Input type='input' value={formData.AdditionalField4EN} onChange={e => setFormData({ ...formData, AdditionalField4EN: e.target.value })} />
                                    </Col>
                                    <Col md={3} className='mb-3'>
                                        <span>Onay Metni</span>
                                        <Input type='input' value={formData.AdditionalField5} onChange={e => setFormData({ ...formData, AdditionalField5: e.target.value })} />
                                    </Col>
                                    <Col md={3} className='mb-3'>
                                        <span>Onay Metni (EN)</span>
                                        <Input type='input' value={formData.AdditionalField5EN} onChange={e => setFormData({ ...formData, AdditionalField5EN: e.target.value })} />
                                    </Col>
                                    <Col md={3} className='mb-3'>
                                        <span>Ana Sayfa Seo</span>
                                        <Select
                                            options={seos}
                                            className='w-100'
                                            style={{ height: 38 }}
                                            value={formData.HomeSeoID}
                                            onChange={e => setFormData({ ...formData, HomeSeoID: e })}
                                        />
                                    </Col>
                                    <Col md={3} className='mb-3'>
                                        <span>İletişim Seo</span>
                                        <Select
                                            options={seos}
                                            className='w-100'
                                            style={{ height: 38 }}
                                            value={formData.ContactSeoID}
                                            onChange={e => setFormData({ ...formData, ContactSeoID: e })}
                                        />
                                    </Col>
                                    <Col md={4} className='mb-3'>
                                        <span>Beyaz Sosyal Medya</span>
                                        <Select
                                            options={socialMedias}
                                            className='w-100'
                                            style={{ height: 38 }}
                                            value={formData.SocialMediaWhiteID}
                                            onChange={e => setFormData({ ...formData, SocialMediaWhiteID: e })}
                                        />
                                    </Col>
                                    <Col md={4} className='mb-3'>
                                        <span>Renkli Sosyal Medya</span>
                                        <Select
                                            options={socialMedias}
                                            className='w-100'
                                            style={{ height: 38 }}
                                            value={formData.SocialMediaDarkID}
                                            onChange={e => setFormData({ ...formData, SocialMediaDarkID: e })}
                                        />
                                    </Col>
                                    <Col md={4} className='mb-3'>
                                        <span>Mail</span>
                                        <Input type='input' value={formData.Mail1} onChange={e => setFormData({ ...formData, Mail1: e.target.value })} />
                                    </Col>
                                    <Col md={6} className='mb-3'>
                                        <span>Mail Başlık</span>
                                        <Input type='input' value={formData.AdditionalField6} onChange={e => setFormData({ ...formData, AdditionalField6: e.target.value })} />
                                    </Col>
                                    <Col md={6} className='mb-3'>
                                        <span>Mail Başlık (EN)</span>
                                        <Input type='input' value={formData.AdditionalField6EN} onChange={e => setFormData({ ...formData, AdditionalField6EN: e.target.value })} />
                                    </Col>
                                    <Col md={12} className='mb-3'>
                                        <span>KVKK</span>
                                        <CKEditor
                                            key={formData.id === 0 ? null : formData.id}
                                            editorUrl="https://cdn.ckeditor.com/4.16.0/standard/ckeditor.js"
                                            data={formData.Kvk}
                                            initData={formData.Kvk}
                                            onChange={handleEditorChange}
                                            onInstanceReady={(event) => {
                                                const editor = event.editor;
                                                editor.on('mode', () => {
                                                    if (editor.mode === 'source') {
                                                        const intervalId = setInterval(() => {
                                                            const data = editor.getData();
                                                            setFormData(prevState => ({ ...prevState, Kvk: data }));
                                                        }, 1000);

                                                        editor.on('mode', () => {
                                                            if (editor.mode !== 'source') {
                                                                clearInterval(intervalId);
                                                            }
                                                        });
                                                    }
                                                });
                                            }}
                                            config={{
                                                toolbar: [
                                                    ['Source', '-', 'Save', 'NewPage', 'Preview', 'Print', '-', 'Templates'],
                                                    ['Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', '-', 'Undo', 'Redo'],
                                                    ['Find', 'Replace', '-', 'SelectAll', '-', 'Scayt'],
                                                    ['Form', 'Checkbox', 'Radio', 'TextField', 'Textarea', 'Select', 'Button', 'ImageButton', 'HiddenField'],
                                                    '/',
                                                    ['Bold', 'Italic', 'Underline', 'Strike', '-', 'TextColor', 'BGColor'],
                                                    ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote', 'CreateDiv'],
                                                    ['JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock'],
                                                    ['BidiLtr', 'BidiRtl'],
                                                    ['Link', 'Unlink', 'Anchor'],
                                                    ['Image', 'Flash', 'Table', 'HorizontalRule', 'Smiley', 'SpecialChar', 'PageBreak', 'Iframe'],
                                                    '/',
                                                    ['Styles', 'Format', 'Font', 'FontSize'],
                                                    ['Maximize', 'ShowBlocks'],
                                                    ['About']
                                                ],
                                                extraPlugins: 'colorbutton,font,justify,bidi,forms,div,find,flash,iframe,pagebreak,smiley,scayt',
                                                removePlugins: 'exportpdf',
                                                allowedContent: true
                                            }}
                                        />
                                    </Col>
                                    <Col md={12} className='mb-3'>
                                        <span>KVKK EN</span>
                                        <CKEditor
                                            key={formData.id === 0 ? null : formData.id}
                                            editorUrl="https://cdn.ckeditor.com/4.16.0/standard/ckeditor.js"
                                            data={formData.KvkEN}
                                            initData={formData.KvkEN}
                                            onChange={handleEditorChangeEN}
                                            onInstanceReady={(event) => {
                                                const editor = event.editor;
                                                editor.on('mode', () => {
                                                    if (editor.mode === 'source') {
                                                        const intervalId = setInterval(() => {
                                                            const data = editor.getData();
                                                            setFormData(prevState => ({ ...prevState, KvkEN: data }));
                                                        }, 1000);

                                                        editor.on('mode', () => {
                                                            if (editor.mode !== 'source') {
                                                                clearInterval(intervalId);
                                                            }
                                                        });
                                                    }
                                                });
                                            }}
                                            config={{
                                                toolbar: [
                                                    ['Source', '-', 'Save', 'NewPage', 'Preview', 'Print', '-', 'Templates'],
                                                    ['Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', '-', 'Undo', 'Redo'],
                                                    ['Find', 'Replace', '-', 'SelectAll', '-', 'Scayt'],
                                                    ['Form', 'Checkbox', 'Radio', 'TextField', 'Textarea', 'Select', 'Button', 'ImageButton', 'HiddenField'],
                                                    '/',
                                                    ['Bold', 'Italic', 'Underline', 'Strike', '-', 'TextColor', 'BGColor'],
                                                    ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote', 'CreateDiv'],
                                                    ['JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock'],
                                                    ['BidiLtr', 'BidiRtl'],
                                                    ['Link', 'Unlink', 'Anchor'],
                                                    ['Image', 'Flash', 'Table', 'HorizontalRule', 'Smiley', 'SpecialChar', 'PageBreak', 'Iframe'],
                                                    '/',
                                                    ['Styles', 'Format', 'Font', 'FontSize'],
                                                    ['Maximize', 'ShowBlocks'],
                                                    ['About']
                                                ],
                                                extraPlugins: 'colorbutton,font,justify,bidi,forms,div,find,flash,iframe,pagebreak,smiley,scayt',
                                                removePlugins: 'exportpdf',
                                                allowedContent: true
                                            }}
                                        />
                                    </Col>

                                    <Col md={12} className='mt-3 d-flex justify-content-end'>
                                        <Button
                                            color='warning'
                                            className='text-white'
                                            onClick={() => editData()}
                                        >
                                            <PlusCircle size={20} style={{ marginTop: -3, marginRight: 7 }} />Güncelle
                                        </Button>
                                    </Col>
                                </Row> : <div className='w-100 mt-5 mb-5 d-flex justify-content-center align-items-center'>
                                    <Spinner style={{ width: '3rem', height: '3rem' }} color='info'> </Spinner>
                                </div>}
                            </CardBody>
                        </Card>
                    </div>
                </Col>
            </Row>
        </motion.div>
    );
};

export default SettingPage;
