export const url2 = 'https://harvestwe-api.test.liberyus.com/api'

export const AUTH_LOGIN = `${url2}/Authentication/Login`
export const AUTH_REGISTER = `${url2}/Authentication/Register`

export const CONTACT_GETALL = `${url2}/Contact/GetAll`
export const CONTACT_GET = `${url2}/Contact/Get`
export const CONTACT_CREATE = `${url2}/Contact/Create`
export const CONTACT_UPDATE = `${url2}/Contact/Update`
export const CONTACT_DELETE = `${url2}/Contact/Delete`

export const DOCUMENT_GETALL = `${url2}/Document/GetAll`
export const DOCUMENT_GETALLBYGROUP = `${url2}/Document/GetAllByGroup`
export const DOCUMENT_GET = `${url2}/Document/Get`
export const DOCUMENT_CREATE = `${url2}/Document/Create`
export const DOCUMENT_UPDATE = `${url2}/Document/Update`
export const DOCUMENT_DELETE = `${url2}/Document/Delete`

export const DOCUMENTGROUP_GETALL = `${url2}/DocumentGroup/GetAll`
export const DOCUMENTGROUP_GET = `${url2}/DocumentGroup/Get`
export const DOCUMENTGROUP_CREATE = `${url2}/DocumentGroup/Create`
export const DOCUMENTGROUP_UPDATE = `${url2}/DocumentGroup/Update`
export const DOCUMENTGROUP_DELETE = `${url2}/DocumentGroup/Delete`

export const DEALER_GETALL = `${url2}/Dealer/GetAll`
export const DEALER_GETALLBYCONTACT = `${url2}/Dealer/GetAllByContact`
export const DEALER_GET = `${url2}/Dealer/Get`
export const DEALER_CREATE = `${url2}/Dealer/Create`
export const DEALER_UPDATE = `${url2}/Dealer/Update`
export const DEALER_DELETE = `${url2}/Dealer/Delete`

export const FORM_GETALL = `${url2}/Form/GetAll`
export const FORM_GET = `${url2}/Form/Get`
export const FORM_CREATE = `${url2}/Form/Create`
export const FORM_UPDATE = `${url2}/Form/Update`
export const FORM_DELETE = `${url2}/Form/Delete`

export const FORMRESPONSE_GETALL = `${url2}/FormResponse/GetAll`
export const FORMRESPONSE_GETALLBYFORM = `${url2}/FormResponse/GetAllByForm`
export const FORMRESPONSE_GET = `${url2}/FormResponse/Get`
export const FORMRESPONSE_CREATE = `${url2}/FormResponse/Create`
export const FORMRESPONSE_DELETE = `${url2}/FormResponse/Delete`

export const HEADER_GETALL = `${url2}/Header/GetAll`
export const HEADER_GET = `${url2}/Header/Get`
export const HEADER_CREATE = `${url2}/Header/Create`
export const HEADER_UPDATE = `${url2}/Header/Update`
export const HEADER_DELETE = `${url2}/Header/Delete`

export const PAGE_GETALL = `${url2}/Page/GetAll`
export const PAGE_GET = `${url2}/Page/Get`
export const PAGE_CREATE = `${url2}/Page/Create`
export const PAGE_UPDATE = `${url2}/Page/Update`
export const PAGE_DELETE = `${url2}/Page/Delete`

export const PRODUCT_GETALL = `${url2}/Product/GetAll`
export const PRODUCT_GETALLBYGROUP = `${url2}/Product/GetAllByGroup`
export const PRODUCT_GET = `${url2}/Product/Get`
export const PRODUCT_CREATE = `${url2}/Product/Create`
export const PRODUCT_UPDATE = `${url2}/Product/Update`
export const PRODUCT_DELETE = `${url2}/Product/Delete`

export const PRODUCTGROUP_GETALL = `${url2}/ProductGroup/GetAll`
export const PRODUCTGROUP_GET = `${url2}/ProductGroup/Get`
export const PRODUCTGROUP_CREATE = `${url2}/ProductGroup/Create`
export const PRODUCTGROUP_UPDATE = `${url2}/ProductGroup/Update`
export const PRODUCTGROUP_DELETE = `${url2}/ProductGroup/Delete`

export const SEO_GETALL = `${url2}/Seo/GetAll`
export const SEO_GET = `${url2}/Seo/Get`
export const SEO_CREATE = `${url2}/Seo/Create`
export const SEO_UPDATE = `${url2}/Seo/Update`
export const SEO_DELETE = `${url2}/Seo/Delete`

export const SETTING_GETALL = `${url2}/Setting/GetAll`
export const SETTING_GET = `${url2}/Setting/Get`
export const SETTING_CREATE = `${url2}/Setting/Create`
export const SETTING_UPDATE = `${url2}/Setting/Update`
export const SETTING_DELETE = `${url2}/Setting/Delete`

export const SLIDER_GETALL = `${url2}/Slider/GetAll`
export const SLIDER_GETALLBYGROUP = `${url2}/Slider/GetAllByGroup`
export const SLIDER_GET = `${url2}/Slider/Get`
export const SLIDER_CREATE = `${url2}/Slider/Create`
export const SLIDER_UPDATE = `${url2}/Slider/Update`
export const SLIDER_DELETE = `${url2}/Slider/Delete`

export const SLIDERGROUP_GETALL = `${url2}/SliderGroup/GetAll`
export const SLIDERGROUP_GET = `${url2}/SliderGroup/Get`
export const SLIDERGROUP_CREATE = `${url2}/SliderGroup/Create`
export const SLIDERGROUP_UPDATE = `${url2}/SliderGroup/Update`
export const SLIDERGROUP_DELETE = `${url2}/SliderGroup/Delete`

export const SOCIAL_GETALL = `${url2}/Social/GetAll`
export const SOCIAL_GETALLBYSOCIAL = `${url2}/Social/GetAllBySocial`
export const SOCIAL_GET = `${url2}/Social/Get`
export const SOCIAL_CREATE = `${url2}/Social/Create`
export const SOCIAL_UPDATE = `${url2}/Social/Update`
export const SOCIAL_DELETE = `${url2}/Social/Delete`

export const SOCIALMEDIA_GETALL = `${url2}/SocialMedia/GetAll`
export const SOCIALMEDIA_GET = `${url2}/SocialMedia/Get`
export const SOCIALMEDIA_CREATE = `${url2}/SocialMedia/Create`
export const SOCIALMEDIA_UPDATE = `${url2}/SocialMedia/Update`
export const SOCIALMEDIA_DELETE = `${url2}/SocialMedia/Delete`

export const USER_GETALL = `${url2}/User/GetAll`
export const USER_GET = `${url2}/User/Get`
export const USER_UPDATE = `${url2}/User/Update`
export const USER_DELETE = `${url2}/User/Delete`
export const USER_CHANGEPASSWORD = `${url2}/User/ChangePassword`
export const USER_POINTCHANGE = `${url2}/User/PointChange`
export const USER_ACTIVEUSERCHANGE = `${url2}/User/ActiveUserChange`