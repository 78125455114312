import React, { useEffect, useState } from 'react';
import { Edit2, Home, List, PlusCircle, Trash2 } from 'react-feather';
import { Button, Card, CardBody, CardHeader, Col, Input, Row, Spinner } from 'reactstrap';
import { motion } from 'framer-motion';
import { Popconfirm, Table, Button as Button2, Tooltip, Select } from 'antd';
import { toast } from 'react-toastify';
import Header from '../../../components/header';
import { createDealer, deleteDealer, getAllDealersByContact, getAllSocialMedias, getDealer, updateDealer } from '../../../core/request';
import { mobilVariant } from '../../../components/animation';
import { useNavigate, useParams } from 'react-router-dom';
import { randomColor } from '../../../components/general';
import { Helmet } from 'react-helmet';

const DealerPage = () => {
    const { id } = useParams()
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false)
    const [socialMedias, setSocialMedias] = useState([])
    const [formData, setFormData] = useState({ id: null, title: "", titleEn: "", city: "", cityEn: "", address: "", addressEn: "", phone: "", phoneEn: "", mail: "", mailEn: "", fax: "", faxEn: "", url: "", addField1: "", addField2: "", addField3: "", addField4: "", addField5: "", socialMediaId: null, contactId: id })

    const clearForm = () => {
        setFormData({ id: null, title: "", titleEn: "", city: "", cityEn: "", address: "", addressEn: "", phone: "", phoneEn: "", mail: "", mailEn: "", fax: "", faxEn: "", url: "", addField1: "", addField2: "", addField3: "", addField4: "", addField5: "", socialMediaId: null, contactId: id })
    }

    const getDatas = async () => {
        const dealerID = await getAllDealersByContact(id);
        const social = await getAllSocialMedias();
        if (dealerID.message === "Success")
            setData(dealerID.result);

        if (social.message === "Success") {
            var newlist = []
            for (var i = 0; i < social.result.length; i++) {
                if (social.result[i] !== undefined)
                    newlist.push({ value: social.result[i].socialMediaID, label: social.result[i].title });
            }
            setSocialMedias(newlist);
        }

        setTimeout(() => {
            setLoading(true)
        }, 1000);
    }

    const getData = async (id) => {
        const data = await getDealer(id);
        setFormData({ id: id, title: data.result.title, titleEn: data.result.titleEN, city: data.result.city, cityEn: data.result.cityEN, address: data.result.address, addressEn: data.result.addressEN, phone: data.result.phone, phoneEn: data.result.phone2, mail: data.result.mail, mailEn: data.result.mail2, fax: data.result.fax, faxEn: data.result.fax2, url: data.result.url, addField1: data.result.addField1, addField2: data.result.addField2, addField3: data.result.addField3, addField4: data.result.addField4, addField5: data.result.addField5, socialMediaId: data.result.socialMediaID, contactId: data.result.contactID })
    }

    const addData = async () => {
        let res;
        if (formData.id) {
            res = await updateDealer(formData.id, formData.title, formData.titleEn, formData.city, formData.cityEn, formData.address, formData.addressEn, formData.phone, formData.phoneEn, formData.mail, formData.mailEn, formData.fax, formData.faxEn, formData.url, formData.addField1, formData.addField2, formData.addField3, formData.addField4, formData.addField5, formData.socialMediaId, formData.contactId);
        } else {
            res = await createDealer(formData.title, formData.titleEn, formData.city, formData.cityEn, formData.address, formData.addressEn, formData.phone, formData.phoneEn, formData.mail, formData.mailEn, formData.fax, formData.faxEn, formData.url, formData.addField1, formData.addField2, formData.addField3, formData.addField4, formData.addField5, formData.socialMediaId, formData.contactId);
        }
        if (res.message === "Success")
            toast.success("İşlem başarılı.")
        else
            toast.error("İşlem sırasında bir sorun oluştu!")
        clearForm()
        getDatas()
    }

    const deleteData = async (id) => {
        const data = await deleteDealer(id);
        if (data.message === "Success")
            toast.success("İşlem başarılı.")
        else
            toast.error("İşlem sırasında bir sorun oluştu!")
        clearForm()
        getDatas()
    }

    const columns = [
        {
            title: "",
            key: "dealerID",
            render: (e) => <div className='table-logo shadow' style={{ backgroundColor: randomColor() }}>{e.title[0]}</div>,
            width: 50
        },
        {
            title: "ID",
            key: "dealerID",
            dataIndex: "dealerID"
        },
        {
            title: "Başlık",
            key: "title",
            render: (e) => e.title
        },
        {
            title: "Şehir",
            key: "city",
            render: (e) => e.city
        },
        {
            title: "İşlemler",
            render: (e) => (
                <div className='d-flex justify-content-start'>
                    <div className='ml-2'>
                        <Tooltip title="Düzenle">
                            <Button2 className='border-warning text-warning' icon={<Edit2 size={20} className='text-warning' />} onClick={() => getData(e.dealerID)} />
                        </Tooltip>
                    </div>
                    <div className='ml-2'>
                        <Popconfirm
                            title="Bu içeriği silmek istediğinizden emin misiniz?"
                            onConfirm={() => deleteData(e.dealerID)}
                            okText="Evet"
                            cancelText="Hayır"
                        >
                            <Tooltip title="Sil">
                                <Button2 icon={<Trash2 size={20} className='text-danger' />} danger />
                            </Tooltip>
                        </Popconfirm>
                    </div>
                </div>
            )
        }
    ];

    useEffect(() => {
        getDatas();
    }, []);

    return (
        <motion.div {...mobilVariant}>
            <Helmet>
                <title>Harvest We - Bayi Yönetimi</title>
            </Helmet>
            <Row className='w-100 pb-5'>
                <Col sm={3} md={3} xl={3}>
                    <Header />
                </Col>
                <Col sm={9} md={9} xl={9}>
                    <div className='right-content mb-5'>
                        <div className='d-flex justify-content-between'>
                            <div><h3>Bayi Yönetimi</h3></div>
                            <div className='d-flex justify-content-end'>
                                <Button onClick={() => navigate("/contact")} className='ml-2 bg-white border border-info text-info'><List size={20} style={{ marginTop: -5 }} /> İletişim Grupları</Button>
                                <Button onClick={() => navigate("/")} className='ml-2 bg-white border border-info text-info'><Home size={20} style={{ marginTop: -5 }} /> Dashboard</Button>
                            </div>
                        </div>
                        <Card className='shadow mt-3'>
                            <CardHeader className='pl-4 shadow-sm d-flex justify-content-between align-items-center bg-white p-3' tag='h4'>Bayi Ekle</CardHeader>
                            <CardBody>
                                <Row className='p-1'>
                                    <Col md={3} className='mb-3'>
                                        <span>Başlık</span>
                                        <Input type='input' value={formData.title} onChange={e => setFormData({ ...formData, title: e.target.value })} />
                                    </Col>
                                    <Col md={3} className='mb-3'>
                                        <span>Başlık (EN)</span>
                                        <Input type='input' value={formData.titleEn} onChange={e => setFormData({ ...formData, titleEn: e.target.value })} />
                                    </Col>
                                    <Col md={3} className='mb-3'>
                                        <span>Şehir</span>
                                        <Input type='input' value={formData.city} onChange={e => setFormData({ ...formData, city: e.target.value })} />
                                    </Col>
                                    <Col md={3} className='mb-3'>
                                        <span>Şehir (EN)</span>
                                        <Input type='input' value={formData.cityEn} onChange={e => setFormData({ ...formData, cityEn: e.target.value })} />
                                    </Col>
                                    <Col md={6} className='mb-3'>
                                        <span>Adres</span>
                                        <Input type='input' value={formData.address} onChange={e => setFormData({ ...formData, address: e.target.value })} />
                                    </Col>
                                    <Col md={6} className='mb-3'>
                                        <span>Adres (EN)</span>
                                        <Input type='input' value={formData.addressEn} onChange={e => setFormData({ ...formData, addressEn: e.target.value })} />
                                    </Col>
                                    <Col md={3} className='mb-3'>
                                        <span>Telefon</span>
                                        <Input type='input' value={formData.phone} onChange={e => setFormData({ ...formData, phone: e.target.value })} />
                                    </Col>
                                    <Col md={3} className='mb-3'>
                                        <span>Telefon 2</span>
                                        <Input type='input' value={formData.phoneEn} onChange={e => setFormData({ ...formData, phoneEn: e.target.value })} />
                                    </Col>
                                    <Col md={3} className='mb-3'>
                                        <span>Mail</span>
                                        <Input type='input' value={formData.mail} onChange={e => setFormData({ ...formData, mail: e.target.value })} />
                                    </Col>
                                    <Col md={3} className='mb-3'>
                                        <span>Mail 2</span>
                                        <Input type='input' value={formData.mailEn} onChange={e => setFormData({ ...formData, mailEn: e.target.value })} />
                                    </Col>
                                    <Col md={3} className='mb-3'>
                                        <span>Fax</span>
                                        <Input type='input' value={formData.fax} onChange={e => setFormData({ ...formData, fax: e.target.value })} />
                                    </Col>
                                    <Col md={3} className='mb-3'>
                                        <span>Fax 2</span>
                                        <Input type='input' value={formData.faxEn} onChange={e => setFormData({ ...formData, faxEn: e.target.value })} />
                                    </Col>
                                    <Col md={3} className='mb-3'>
                                        <span>URL</span>
                                        <Input type='input' value={formData.url} onChange={e => setFormData({ ...formData, url: e.target.value })} />
                                    </Col>
                                    <Col md={3} className='mb-3'>
                                        <span>Sosyal Medya</span>
                                        <Select
                                            options={socialMedias}
                                            className='w-100'
                                            style={{ height: 38 }}
                                            value={formData.socialMediaId}
                                            onChange={e => setFormData({ ...formData, socialMediaId: e })}
                                        />
                                    </Col>
                                    <Col md={12} className='mt-3 d-flex justify-content-end'>
                                        {formData.id ? <Button onClick={() => clearForm()} className='bg-white border-warning text-warning'>Temizle</Button> : null}
                                        <Button
                                            color={formData.id ? 'warning' : 'success'}
                                            onClick={() => addData()}
                                            className='text-white'
                                        >
                                            <PlusCircle size={20} style={{ marginTop: -3, marginRight: 7 }} />{formData.id ? "Güncelle" : "Ekle"}
                                        </Button>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        <Card className='shadow mt-4'>
                            <CardHeader className='pl-4 d-flex shadow-sm justify-content-between align-items-center bg-white p-3'>
                                <div><h4>Bayi Listesi</h4></div>
                            </CardHeader>
                            <CardBody>
                                {loading ? <Table
                                    rowKey='dealerID'
                                    dataSource={data}
                                    columns={columns}
                                    scroll={{ x: 'max-content' }}
                                /> : <div className='w-100 mt-5 mb-5 d-flex justify-content-center align-items-center'>
                                    <Spinner style={{ width: '3rem', height: '3rem' }} color='info'> </Spinner>
                                </div>}
                            </CardBody>
                        </Card>
                    </div>
                </Col>
            </Row>
        </motion.div>
    );
};

export default DealerPage;
