import React, { useEffect, useState } from 'react';
import { Home, MessageCircle, Trash2 } from 'react-feather';
import { Button, Card, CardBody, CardHeader, Col, Row, Spinner } from 'reactstrap';
import { motion } from 'framer-motion';
import { Popconfirm, Table, Button as Button2 } from 'antd';
import { toast } from 'react-toastify';
import Header from '../../../components/header';
import { deleteFormResponse, getAllFormResponses } from '../../../core/request';
import { mobilVariant } from '../../../components/animation';
import { useNavigate, useParams } from 'react-router-dom';
import { randomColor, splitF } from '../../../components/general';
import { Helmet } from 'react-helmet';

const FormResponsePage = () => {
    const { id } = useParams();
    const navigate = useNavigate()
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false)

    const getDatas = async () => {
        const formElements = await getAllFormResponses(id);
        if (formElements.message === "Success")
            setData(formElements.result);

        setTimeout(() => {
            setLoading(true)
        }, 1000);
    }

    const deleteData = async (id) => {
        const formElement = await deleteFormResponse(id);
        if (formElement.message === "Success")
            toast.success("İşlem başarılı.")
        else
            toast.error("İşlem sırasında bir sorun oluştu!")
        getDatas()
    }

    const columns = [
        {
            title: "",
            key: "formResponseID",
            render: (e) => <div className='table-logo shadow' style={{ backgroundColor: randomColor() }}>{e.name[0]}{e.surname[0]}</div>,
            width: 50
        },
        {
            title: "ID",
            key: "formResponseID",
            dataIndex: "formResponseID"
        },
        {
            title: "İsim Soyisim",
            key: "name",
            render: (e) => `${e.name} ${e.surname}`
        },
        {
            title: "E-Mail",
            key: "mail",
            dataIndex: "mail"
        },
        {
            title: "Telefon",
            key: "phone",
            dataIndex: "phone"
        },
        {
            title: "Mesaj",
            key: "message",
            render: (e) => splitF(e.message, 50)
        },
        {
            title: "İşlemler",
            render: (e) => (
                <div className='d-flex justify-content-start'>
                    <div className='ml-2'>
                        <Popconfirm
                            title="Bu form cevabını silmek istediğinizden emin misiniz?"
                            onConfirm={() => deleteData(e.formResponseID)}
                            okText="Evet"
                            cancelText="Hayır"
                        >
                            <Button2 icon={<Trash2 size={20} className='text-danger' />} danger />
                        </Popconfirm>
                    </div>
                </div>
            )
        }
    ];

    useEffect(() => {
        getDatas();
    }, [])

    return (
        <motion.div {...mobilVariant}>
            <Helmet>
                <title>Harvest We - Form Cevapları</title>
            </Helmet>
            <Row className='w-100 pb-5'>
                <Col sm={3} md={3} xl={3}>
                    <Header />
                </Col>
                <Col sm={9} md={9} xl={9}>
                    <div className='right-content mb-5'>
                        <div className='d-flex justify-content-between'>
                            <div><h3>{ }</h3></div>
                            <div className='d-flex justify-content-end'>
                                <Button color='info' onClick={() => navigate("/form")}><MessageCircle size={20} style={{ marginTop: -3, marginRight: 7 }} />Form Listesi</Button>
                                <Button className='ml-2 bg-white border border-info text-info'><Home size={20} style={{ marginTop: -5 }} /> Dashboard</Button>
                            </div>
                        </div>

                        <Card className='shadow mt-4'>
                            <CardHeader className='pl-4 d-flex shadow-sm justify-content-between align-items-center bg-white p-3'>
                                <div><h4>Form Cevapları Listesi</h4></div>
                            </CardHeader>
                            <CardBody>
                                {loading ? <Table
                                    rowKey='formResponseID'
                                    dataSource={data}
                                    columns={columns}
                                    scroll={{ x: 'max-content' }}
                                /> : <div className='w-100 mt-5 mb-5 d-flex justify-content-center align-items-center'>
                                    <Spinner style={{ width: '3rem', height: '3rem' }} color='info'> </Spinner>
                                </div>}
                            </CardBody>
                        </Card>
                    </div>
                </Col>
            </Row>
        </motion.div >
    );
};

export default FormResponsePage;
