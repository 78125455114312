import axios from "axios";
import { AUTH_LOGIN, AUTH_REGISTER, CONTACT_CREATE, CONTACT_DELETE, CONTACT_GET, CONTACT_GETALL, CONTACT_UPDATE, DEALER_CREATE, DEALER_DELETE, DEALER_GET, DEALER_GETALL, DEALER_UPDATE, DOCUMENT_CREATE, DOCUMENT_DELETE, DOCUMENT_GET, DOCUMENT_GETALL, DOCUMENT_GETALLBYGROUP, DOCUMENT_UPDATE, DOCUMENTGROUP_CREATE, DOCUMENTGROUP_DELETE, DOCUMENTGROUP_GET, DOCUMENTGROUP_GETALL, DOCUMENTGROUP_UPDATE, FORM_CREATE, FORM_DELETE, FORM_GET, FORM_GETALL, FORM_UPDATE, FORMRESPONSE_CREATE, FORMRESPONSE_DELETE, FORMRESPONSE_GET, FORMRESPONSE_GETALL, FORMRESPONSE_GETALLBYFORM, HEADER_CREATE, HEADER_DELETE, HEADER_GET, HEADER_GETALL, HEADER_UPDATE, PAGE_CREATE, PAGE_DELETE, PAGE_GET, PAGE_GETALL, PAGE_UPDATE, PRODUCT_CREATE, PRODUCT_DELETE, PRODUCT_GET, PRODUCT_GETALL, PRODUCT_GETALLBYGROUP, PRODUCT_UPDATE, PRODUCTGROUP_CREATE, PRODUCTGROUP_DELETE, PRODUCTGROUP_GET, PRODUCTGROUP_GETALL, PRODUCTGROUP_UPDATE, SEO_CREATE, SEO_DELETE, SEO_GET, SEO_GETALL, SEO_UPDATE, SETTING_CREATE, SETTING_DELETE, SETTING_GET, SETTING_GETALL, SETTING_UPDATE, SLIDER_CREATE, SLIDER_DELETE, SLIDER_GET, SLIDER_GETALL, SLIDER_GETALLBYGROUP, SLIDER_UPDATE, SLIDERGROUP_CREATE, SLIDERGROUP_DELETE, SLIDERGROUP_GET, SLIDERGROUP_GETALL, SLIDERGROUP_UPDATE, SOCIAL_CREATE, SOCIAL_DELETE, SOCIAL_GET, SOCIAL_GETALL, SOCIAL_GETALLBYSOCIAL, SOCIAL_UPDATE, SOCIALMEDIA_CREATE, SOCIALMEDIA_DELETE, SOCIALMEDIA_GET, SOCIALMEDIA_GETALL, SOCIALMEDIA_UPDATE, USER_ACTIVEUSERCHANGE, USER_CHANGEPASSWORD, USER_DELETE, USER_GET, USER_GETALL, USER_UPDATE } from "../api";
import { toast } from "react-toastify";

const token = localStorage.getItem("auth") === null ? null : JSON.parse(localStorage.getItem("auth")).accessToken
const userId = localStorage.getItem("auth") === null ? null : JSON.parse(localStorage.getItem("auth")).userId
const header = { headers: { "Authorization": `Bearer ${token}` } }



// Authentication
export function login(userName, password) {
    return axios.post(AUTH_LOGIN, { "userName": userName, "password": password })
        .then(res => res.data).catch(er => toast.error(er))
}

export function register(firstName, lastName, userName, email, phoneNumber, password, roles) {
    return axios.post(AUTH_REGISTER, { "firstName": firstName, "lastName": lastName, "userName": userName, "email": email, "phoneNumber": phoneNumber, "password": password, "roles": roles })
        .then(res => res.data).catch(er => toast.error(er))
}
// Authentication END



// Contact
export function getAllContacts() {
    return axios.get(CONTACT_GETALL, header)
        .then(res => res.data).catch(er => toast.error(er))
}

export function getContact(id) {
    return axios.get(`${CONTACT_GET}/${id}`, header)
        .then(res => res.data).catch(er => toast.error(er))
}

export function createContact(title, titleEN, description, descriptionEN, addField1, addField2, addField3, addField4, addField5) {
    return axios.post(CONTACT_CREATE, { "title": title, "titleEN": titleEN, "description": description, "descriptionEN": descriptionEN, "addField1": addField1, "addField2": addField2, "addField3": addField3, "addField4": addField4, "addField5": addField5, "userId": userId }, header)
        .then(res => res.data).catch(er => toast.error(er))
}

export function updateContact(id, title, titleEN, description, descriptionEN, addField1, addField2, addField3, addField4, addField5) {
    return axios.put(`${CONTACT_UPDATE}/${id}`, { "title": title, "titleEN": titleEN, "description": description, "descriptionEN": descriptionEN, "addField1": addField1, "addField2": addField2, "addField3": addField3, "addField4": addField4, "addField5": addField5, "userId": userId, "dealerID": id }, header)
        .then(res => res.data).catch(er => toast.error(er))
}

export function deleteContact(id) {
    return axios.delete(`${CONTACT_DELETE}/${id}`, header)
        .then(res => res.data).catch(er => toast.error(er))
}
// Contact END



// Dealer
export function getAllDealers() {
    return axios.get(DEALER_GETALL, header)
        .then(res => res.data).catch(er => toast.error(er))
}

export function getAllDealersByContact(id) {
    return axios.get(DEALER_GETALL, header)
        .then(res => res.data).catch(er => toast.error(er))
}

export function getDealer(id) {
    return axios.get(`${DEALER_GET}/${id}`, header)
        .then(res => res.data).catch(er => toast.error(er))
}

export function createDealer(title, titleEN, city, cityEN, address, addressEn, phone, phone2, mail, mail2, fax, fax2, url, addField1, addField2, addField3, addField4, addField5, socialMediaID, contactID) {
    return axios.post(DEALER_CREATE, { "title": title, "titleEN": titleEN, "city": city, "cityEN": cityEN, "address": address, "addressEN": addressEn, "phone": phone, "phone2": phone2, "mail": mail, "mail2": mail2, "fax": fax, "fax2": fax2, "url": url, "addField1": addField1, "addField2": addField2, "addField3": addField3, "addField4": addField4, "addField5": addField5, "socialMediaID": socialMediaID, "contactID": contactID, "userId": userId }, header)
        .then(res => res.data).catch(er => toast.error(er))
}

export function updateDealer(id, title, titleEN, city, cityEN, address, addressEn, phone, phone2, mail, mail2, fax, fax2, url, addField1, addField2, addField3, addField4, addField5, socialMediaID, contactID) {
    return axios.put(`${DEALER_UPDATE}/${id}`, { "title": title, "titleEN": titleEN, "city": city, "cityEN": cityEN, "address": address, "addressEN": addressEn, "phone": phone, "phone2": phone2, "mail": mail, "mail2": mail2, "fax": fax, "fax2": fax2, "url": url, "addField1": addField1, "addField2": addField2, "addField3": addField3, "addField4": addField4, "addField5": addField5, "socialMediaID": socialMediaID, "contactID": contactID, "userId": userId, "dealerID": id }, header)
        .then(res => res.data).catch(er => toast.error(er))
}

export function deleteDealer(id) {
    return axios.delete(`${DEALER_DELETE}/${id}`, header)
        .then(res => res.data).catch(er => toast.error(er))
}
// Dealer END



// Document
export function getAllDocuments() {
    return axios.get(DOCUMENT_GETALL, header)
        .then(res => res.data).catch(er => toast.error(er))
}

export function getAllDocumentsByGroup(id) {
    return axios.get(`${DOCUMENT_GETALLBYGROUP}/${id}`, header)
        .then(res => res.data).catch(er => toast.error(er))
}

export function getDocument(id) {
    return axios.get(`${DOCUMENT_GET}/${id}`, header)
        .then(res => res.data).catch(er => toast.error(er))
}

export function createDocument(file, title, titleen, DocumentGroupID) {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("Title", title);
    formData.append("TitleEN", titleen);
    formData.append("DocumentGroupID", DocumentGroupID);
    formData.append("UserId", userId);

    return axios.post(DOCUMENT_CREATE, formData, header)
        .then(res => res.data).catch(er => toast.error(er))
}

export function updateDocument(id, file, title, titleen, DocumentGroupID) {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("Title", title);
    formData.append("TitleEN", titleen);
    formData.append("DocumentGroupID", DocumentGroupID);
    formData.append("UserId", userId);
    formData.append("DocumentID", id);

    return axios.put(DOCUMENT_UPDATE, formData, header)
        .then(res => res.data).catch(er => toast.error(er))
}

export function deleteDocument(id) {
    return axios.delete(`${DOCUMENT_DELETE}/${id}`, header)
        .then(res => res.data).catch(er => toast.error(er))
}
// Document END



// DocumentGroup
export function getAllDocumentGroups() {
    return axios.get(DOCUMENTGROUP_GETALL, header)
        .then(res => res.data).catch(er => toast.error(er))
}

export function getDocumentGroup(id) {
    return axios.get(`${DOCUMENTGROUP_GET}/${id}`, header)
        .then(res => res.data).catch(er => toast.error(er))
}

export function createDocumentGroup(title, titleEN, description, descriptionEN) {
    return axios.post(DOCUMENTGROUP_CREATE, { "title": title, "titleEN": titleEN, "description": description, "descriptionEN": descriptionEN, "userId": userId }, header)
        .then(res => res.data).catch(er => toast.error(er))
}

export function updateDocumentGroup(id, title, titleEN, description, descriptionEN) {
    return axios.put(`${DOCUMENTGROUP_UPDATE}/${id}`, { "title": title, "titleEN": titleEN, "description": description, "descriptionEN": descriptionEN, "userId": userId, "documentGroupID": id }, header)
        .then(res => res.data).catch(er => toast.error(er))
}

export function deleteDocumentGroup(id) {
    return axios.delete(`${DOCUMENTGROUP_DELETE}/${id}`, header)
        .then(res => res.data).catch(er => toast.error(er))
}
// DocumentGroup END



// Form
export function getAllForms() {
    return axios.get(FORM_GETALL, header)
        .then(res => res.data).catch(er => toast.error(er))
}

export function getForm(id) {
    return axios.get(`${FORM_GET}/${id}`, header)
        .then(res => res.data).catch(er => toast.error(er))
}

export function createForm(title, titleEN, description, descriptionEN) {
    return axios.post(FORM_CREATE, { "title": title, "titleEN": titleEN, "description": description, "descriptionEN": descriptionEN, "userId": userId }, header)
        .then(res => res.data).catch(er => toast.error(er))
}

export function updateForm(id, title, titleEN, description, descriptionEN) {
    return axios.put(`${FORM_UPDATE}/${id}`, { "title": title, "titleEN": titleEN, "description": description, "descriptionEN": descriptionEN, "userId": userId, "formID": id }, header)
        .then(res => res.data).catch(er => toast.error(er))
}

export function deleteForm(id) {
    return axios.delete(`${FORM_DELETE}/${id}`, header)
        .then(res => res.data).catch(er => toast.error(er))
}
// Form END



// FormResponse
export function getAllFormResponses() {
    return axios.get(FORMRESPONSE_GETALL, header)
        .then(res => res.data).catch(er => toast.error(er))
}

export function getAllFormResponsesByForm(id) {
    return axios.get(`${FORMRESPONSE_GETALLBYFORM}/${id}`, header)
        .then(res => res.data).catch(er => toast.error(er))
}

export function getFormResponse(id) {
    return axios.get(`${FORMRESPONSE_GET}/${id}`, header)
        .then(res => res.data).catch(er => toast.error(er))
}

export function createFormResponse(name, surname, mail, phone, subject, message, formID) {
    return axios.post(FORMRESPONSE_CREATE, { "name": name, "surname": surname, "mail": mail, "phone": phone, "subject": subject, "message": message, "formID": formID, "userId": userId }, header)
        .then(res => res.data).catch(er => toast.error(er))
}

export function deleteFormResponse(id) {
    return axios.delete(`${FORMRESPONSE_DELETE}/${id}`, header)
        .then(res => res.data).catch(er => toast.error(er))
}
// FormResponse END



// Header
export function getAllHeaders() {
    return axios.get(HEADER_GETALL, header)
        .then(res => res.data).catch(er => toast.error(er))
}

export function getHeader(id) {
    return axios.get(`${HEADER_GET}/${id}`, header)
        .then(res => res.data).catch(er => toast.error(er))
}

export function createHeader(title, titleEN, longTitle, longTitleEN, url, urlEN, parentHeaderID) {
    return axios.post(HEADER_CREATE, { "title": title, "titleEN": titleEN, "longTitle": longTitle, "longTitleEN": longTitleEN, "url": url, "urlEN": urlEN, "parentHeaderID": parentHeaderID, "userId": userId }, header)
        .then(res => res.data).catch(er => toast.error(er))
}

export function updateHeader(id, title, titleEN, longTitle, longTitleEN, url, urlEN, parentHeaderID) {
    return axios.put(`${HEADER_UPDATE}/${id}`, { "title": title, "titleEN": titleEN, "longTitle": longTitle, "longTitleEN": longTitleEN, "url": url, "urlEN": urlEN, "parentHeaderID": parentHeaderID, "userId": userId, "headerID": id }, header)
        .then(res => res.data).catch(er => toast.error(er))
}

export function deleteHeader(id) {
    return axios.delete(`${HEADER_DELETE}/${id}`, header)
        .then(res => res.data).catch(er => toast.error(er))
}
// Header END



// Page
export function getAllPages() {
    return axios.get(PAGE_GETALL, header)
        .then(res => res.data).catch(er => toast.error(er))
}

export function getPage(id) {
    return axios.get(`${PAGE_GET}/${id}`, header)
        .then(res => res.data).catch(er => toast.error(er))
}

export function createPage(file, title, titleen, desc, descEn, content, contentEn, documentGroupId, seoId, headerId) {
    const formData = new FormData();
    console.log(documentGroupId)
    formData.append("file", file);
    formData.append("Title", title);
    formData.append("TitleEN", titleen);
    formData.append("Description", desc);
    formData.append("DescriptionEN", descEn);
    formData.append("Content", content);
    formData.append("ContentEN", contentEn);
    formData.append("headerID", headerId);
    formData.append("SeoID", seoId);
    if (documentGroupId !== null) {
        formData.append("DocumentGroupID", documentGroupId);
    }
    formData.append("UserId", userId);

    return axios.post(PAGE_CREATE, formData, header)
        .then(res => res.data).catch(er => toast.error(er))
}

export function updatePage(id, file, title, titleen, desc, descEn, content, contentEn, documentGroupId, seoId, headerId) {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("Title", title);
    formData.append("TitleEN", titleen);
    formData.append("Description", desc);
    formData.append("DescriptionEN", descEn);
    formData.append("Content", content);
    formData.append("ContentEN", contentEn);
    formData.append("headerID", headerId);
    formData.append("SeoID", seoId);
    if (documentGroupId !== null) {
        formData.append("DocumentGroupID", documentGroupId);
    }
    formData.append("UserId", userId);
    formData.append("PageID", id);

    return axios.put(PAGE_UPDATE, formData, header)
        .then(res => res.data).catch(er => toast.error(er))
}

export function deletePage(id) {
    return axios.delete(`${PAGE_DELETE}/${id}`, header)
        .then(res => res.data).catch(er => toast.error(er))
}
// Page END



// Product
export function getAllProducts() {
    return axios.get(PRODUCT_GETALL, header)
        .then(res => res.data).catch(er => toast.error(er))
}

export function getAllProductsByGroup(id) {
    return axios.get(`${PRODUCT_GETALLBYGROUP}/${id}`, header)
        .then(res => res.data).catch(er => toast.error(er))
}

export function getProduct(id) {
    return axios.get(`${PRODUCT_GET}/${id}`, header)
        .then(res => res.data).catch(er => toast.error(er))
}

export function createProduct(file, title, titleEN, description, descriptionEN, url, urlEN, AdditionalField1, AdditionalField1EN, AdditionalField2, AdditionalField2EN, AdditionalField3, AdditionalField3EN, ProductGroupID) {
    const formData = new FormData();
    if (file !== null) {
        formData.append("file", file);
    }
    formData.append("Title", title);
    formData.append("TitleEN", titleEN);
    formData.append("Description", description);
    formData.append("DescriptionEN", descriptionEN);
    formData.append("URL", url);
    formData.append("URLEN", urlEN);
    formData.append("AdditionalField1", AdditionalField1);
    formData.append("AdditionalField1EN", AdditionalField1EN);
    formData.append("AdditionalField2", AdditionalField2);
    formData.append("AdditionalField2EN", AdditionalField2EN);
    formData.append("AdditionalField3", AdditionalField3);
    formData.append("AdditionalField3EN", AdditionalField3EN);
    formData.append("ProductGroupID", ProductGroupID);
    formData.append("UserId", userId);

    return axios.post(PRODUCT_CREATE, formData, header)
        .then(res => res.data).catch(er => toast.error(er))
}

export function updateProduct(id, file, title, titleEN, description, descriptionEN, url, urlEN, AdditionalField1, AdditionalField1EN, AdditionalField2, AdditionalField2EN, AdditionalField3, AdditionalField3EN, ProductGroupID) {
    const formData = new FormData();
    if (file !== null) {
        formData.append("file", file);
    }
    formData.append("Title", title);
    formData.append("TitleEN", titleEN);
    formData.append("Description", description);
    formData.append("DescriptionEN", descriptionEN);
    formData.append("URL", url);
    formData.append("URLEN", urlEN);
    formData.append("AdditionalField1", AdditionalField1);
    formData.append("AdditionalField1EN", AdditionalField1EN);
    formData.append("AdditionalField2", AdditionalField2);
    formData.append("AdditionalField2EN", AdditionalField2EN);
    formData.append("AdditionalField3", AdditionalField3);
    formData.append("AdditionalField3EN", AdditionalField3EN);
    formData.append("ProductGroupID", ProductGroupID);
    formData.append("UserId", userId);
    formData.append("ProductID", id);

    return axios.put(PRODUCT_UPDATE, formData, header)
        .then(res => res.data).catch(er => toast.error(er))
}

export function deleteProduct(id) {
    return axios.delete(`${PRODUCT_DELETE}/${id}`, header)
        .then(res => res.data).catch(er => toast.error(er))
}
// Product END



// ProductGroup
export function getAllProductGroups() {
    return axios.get(PRODUCTGROUP_GETALL, header)
        .then(res => res.data).catch(er => toast.error(er))
}

export function getProductGroup(id) {
    return axios.get(`${PRODUCTGROUP_GET}/${id}`, header)
        .then(res => res.data).catch(er => toast.error(er))
}

export function createProductGroup(file, file2, file3, title, titleEN, description, descriptionEN, content, contentEN, AdditionalField1, AdditionalField1EN, AdditionalField2, AdditionalField2EN, AdditionalField3, AdditionalField3EN, SeoID, HeaderID, DocumentGroupID) {
    const formData = new FormData();
    if (file !== null) {
        formData.append("file", file);
    }
    if (file2 !== null) {
        formData.append("file2", file2);
    }
    if (file3 !== null) {
        formData.append("file3", file3);
    }
    formData.append("Title", title);
    formData.append("TitleEN", titleEN);
    formData.append("Description", description);
    formData.append("DescriptionEN", descriptionEN);
    formData.append("Content", content);
    formData.append("ContentEN", contentEN);
    formData.append("AdditionalField1", AdditionalField1);
    formData.append("AdditionalField1EN", AdditionalField1EN);
    formData.append("AdditionalField2", AdditionalField2);
    formData.append("AdditionalField2EN", AdditionalField2EN);
    formData.append("AdditionalField3", AdditionalField3);
    formData.append("AdditionalField3EN", AdditionalField3EN);
    formData.append("SeoID", SeoID);
    formData.append("HeaderID", HeaderID);
    if (DocumentGroupID !== null) {
        formData.append("DocumentGroupID", DocumentGroupID);
    }
    formData.append("UserId", userId);

    return axios.post(PRODUCTGROUP_CREATE, formData, header)
        .then(res => res.data).catch(er => toast.error(er))
}

export function updateProductGroup(id, file, file2, file3, title, titleEN, description, descriptionEN, content, contentEN, AdditionalField1, AdditionalField1EN, AdditionalField2, AdditionalField2EN, AdditionalField3, AdditionalField3EN, SeoID, HeaderID, DocumentGroupID) {
    const formData = new FormData();
    if (file !== null) {
        formData.append("file", file);
    }
    if (file2 !== null) {
        formData.append("file2", file2);
    }
    if (file3 !== null) {
        formData.append("file3", file3);
    }
    formData.append("Title", title);
    formData.append("TitleEN", titleEN);
    formData.append("Description", description);
    formData.append("DescriptionEN", descriptionEN);
    formData.append("Content", content);
    formData.append("ContentEN", contentEN);
    formData.append("AdditionalField1", AdditionalField1);
    formData.append("AdditionalField1EN", AdditionalField1EN);
    formData.append("AdditionalField2", AdditionalField2);
    formData.append("AdditionalField2EN", AdditionalField2EN);
    formData.append("AdditionalField3", AdditionalField3);
    formData.append("AdditionalField3EN", AdditionalField3EN);
    formData.append("SeoID", SeoID);
    formData.append("HeaderID", HeaderID);
    if (DocumentGroupID !== null) {
        formData.append("DocumentGroupID", DocumentGroupID);
    }
    formData.append("UserId", userId);
    formData.append("ProductGroupID", id);

    return axios.put(PRODUCTGROUP_UPDATE, formData, header)
        .then(res => res.data).catch(er => toast.error(er))
}

export function deleteProductGroup(id) {
    return axios.delete(`${PRODUCTGROUP_DELETE}/${id}`, header)
        .then(res => res.data).catch(er => toast.error(er))
}
// ProductGroup END



// Seo
export function getAllSeos() {
    return axios.get(SEO_GETALL, header)
        .then(res => res.data).catch(er => toast.error(er))
}

export function getSeo(id) {
    return axios.get(`${SEO_GET}/${id}`, header)
        .then(res => res.data).catch(er => toast.error(er))
}

export function createSeo(title, titleEN, description, descriptionEN, keywords, keywordsEN, author, authorEN) {
    return axios.post(SEO_CREATE, { "title": title, "titleEN": titleEN, "description": description, "descriptionEN": descriptionEN, "keywords": keywords, "keywordsEN": keywordsEN, "author": author, "authorEN": authorEN, "userId": userId }, header)
        .then(res => res.data).catch(er => toast.error(er))
}

export function updateSeo(id, title, titleEN, description, descriptionEN, keywords, keywordsEN, author, authorEN) {
    return axios.put(`${SEO_UPDATE}/${id}`, { "title": title, "titleEN": titleEN, "description": description, "descriptionEN": descriptionEN, "keywords": keywords, "keywordsEN": keywordsEN, "author": author, "authorEN": authorEN, "userId": userId, "seoID": id }, header)
        .then(res => res.data).catch(er => toast.error(er))
}

export function deleteSeo(id) {
    return axios.delete(`${SEO_DELETE}/${id}`, header)
        .then(res => res.data).catch(er => toast.error(er))
}
// Seo END



// Setting
export function getAllSettings() {
    return axios.get(SETTING_GETALL, header)
        .then(res => res.data).catch(er => toast.error(er))
}

export function getSetting() {
    return axios.get(`${SETTING_GET}/1`, header)
        .then(res => res.data).catch(er => toast.error(er))
}

export function createSetting(file, file2, file3, file4, file5, file6, file7, file8, FooterText, FooterTextEN, Kvk, KvkEN, AdditionalField1, AdditionalField1EN, AdditionalField2, AdditionalField2EN, AdditionalField3, AdditionalField3EN, AdditionalField4, AdditionalField4EN, AdditionalField5, AdditionalField5EN, AdditionalField6, AdditionalField6EN, AdditionalField7, AdditionalField7EN, AdditionalField8, AdditionalField8EN, AdditionalField9, AdditionalField9EN, AdditionalField10, AdditionalField10EN, HomeSeoID, ContactSeoID, SocialMediaDarkID, SocialMediaWhiteID, Phone1, Phone2, Phone3, Mail1, Mail2, Mail3, Fax1, Fax2, Fax3) {
    const formData = new FormData()
    formData.append("file", file);
    formData.append("file2", file2);
    formData.append("file3", file3);
    formData.append("file4", file4);
    formData.append("file5", file5);
    formData.append("file6", file6);
    formData.append("file7", file7);
    formData.append("file8", file8);
    formData.append("FooterText", FooterText);
    formData.append("FooterTextEN", FooterTextEN);
    formData.append("Kvk", Kvk);
    formData.append("KvkEN", KvkEN);
    formData.append("AdditionalField1", AdditionalField1);
    formData.append("AdditionalField1EN", AdditionalField1EN);
    formData.append("AdditionalField2", AdditionalField2);
    formData.append("AdditionalField2EN", AdditionalField2EN);
    formData.append("AdditionalField3", AdditionalField3);
    formData.append("AdditionalField3EN", AdditionalField3EN);
    formData.append("AdditionalField4", AdditionalField4);
    formData.append("AdditionalField4EN", AdditionalField4EN);
    formData.append("AdditionalField5", AdditionalField5);
    formData.append("AdditionalField5EN", AdditionalField5EN);
    formData.append("AdditionalField6", AdditionalField6);
    formData.append("AdditionalField6EN", AdditionalField6EN);
    formData.append("AdditionalField7", AdditionalField7);
    formData.append("AdditionalField7EN", AdditionalField7EN);
    formData.append("AdditionalField8", AdditionalField8);
    formData.append("AdditionalField8EN", AdditionalField8EN);
    formData.append("AdditionalField9", AdditionalField9);
    formData.append("AdditionalField9EN", AdditionalField9EN);
    formData.append("AdditionalField10", AdditionalField10);
    formData.append("AdditionalField10EN", AdditionalField10EN);
    formData.append("SocialMediaDarkID", SocialMediaDarkID);
    formData.append("SocialMediaWhiteID", SocialMediaWhiteID);
    formData.append("Phone1", Phone1);
    formData.append("Phone2", Phone2);
    formData.append("Phone3", Phone3);
    formData.append("Mail1", Mail1);
    formData.append("Mail2", Mail2);
    formData.append("Mail3", Mail3);
    formData.append("Fax1", Fax1);
    formData.append("Fax2", Fax2);
    formData.append("Fax3", Fax3);
    formData.append("HomeSeoID", HomeSeoID);
    formData.append("ContactSeoID", ContactSeoID);
    formData.append("UserId", userId);
    return axios.post(SETTING_CREATE, formData, header)
        .then(res => res.data).catch(er => toast.error(er))
}

export function updateSetting(id, file, file2, file3, file4, file5, file6, file7, file8, FooterText, FooterTextEN, Kvk, KvkEN, AdditionalField1, AdditionalField1EN, AdditionalField2, AdditionalField2EN, AdditionalField3, AdditionalField3EN, AdditionalField4, AdditionalField4EN, AdditionalField5, AdditionalField5EN, AdditionalField6, AdditionalField6EN, AdditionalField7, AdditionalField7EN, AdditionalField8, AdditionalField8EN, AdditionalField9, AdditionalField9EN, AdditionalField10, AdditionalField10EN, HomeSeoID, ContactSeoID, SocialMediaDarkID, SocialMediaWhiteID, Phone1, Phone2, Phone3, Mail1, Mail2, Mail3, Fax1, Fax2, Fax3) {
    console.log(file7)
    const formData = new FormData()
    if (file !== null) formData.append("file", file);
    if (file2 !== null) formData.append("file2", file2);
    if (file3 !== null) formData.append("file3", file3);
    if (file4 !== null) formData.append("file4", file4);
    if (file5 !== null) formData.append("file5", file5);
    if (file6 !== null) formData.append("file6", file6);
    if (file7 !== null) formData.append("file7", file7);
    if (file8 !== null) formData.append("file8", file8);
    formData.append("FooterText", FooterText);
    formData.append("FooterTextEN", FooterTextEN);
    formData.append("Kvk", Kvk);
    formData.append("KvkEN", KvkEN);
    formData.append("AdditionalField1", AdditionalField1);
    formData.append("AdditionalField1EN", AdditionalField1EN);
    formData.append("AdditionalField2", AdditionalField2);
    formData.append("AdditionalField2EN", AdditionalField2EN);
    formData.append("AdditionalField3", AdditionalField3);
    formData.append("AdditionalField3EN", AdditionalField3EN);
    formData.append("AdditionalField4", AdditionalField4);
    formData.append("AdditionalField4EN", AdditionalField4EN);
    formData.append("AdditionalField5", AdditionalField5);
    formData.append("AdditionalField5EN", AdditionalField5EN);
    formData.append("AdditionalField6", AdditionalField6);
    formData.append("AdditionalField6EN", AdditionalField6EN);
    formData.append("AdditionalField7", AdditionalField7);
    formData.append("AdditionalField7EN", AdditionalField7EN);
    formData.append("AdditionalField8", AdditionalField8);
    formData.append("AdditionalField8EN", AdditionalField8EN);
    formData.append("AdditionalField9", AdditionalField9);
    formData.append("AdditionalField9EN", AdditionalField9EN);
    formData.append("AdditionalField10", AdditionalField10);
    formData.append("AdditionalField10EN", AdditionalField10EN);
    if (SocialMediaDarkID !== null) formData.append("SocialMediaDarkID", SocialMediaDarkID);
    if (SocialMediaWhiteID !== null) formData.append("SocialMediaWhiteID", SocialMediaWhiteID);
    formData.append("Phone1", Phone1 || "");
    formData.append("Phone2", Phone2 || "");
    formData.append("Phone3", Phone3 || "");
    formData.append("Mail1", Mail1 || "");
    formData.append("Mail2", Mail2 || "");
    formData.append("Mail3", Mail3 || "");
    formData.append("Fax1", Fax1 || "");
    formData.append("Fax2", Fax2 || "");
    formData.append("Fax3", Fax3 || "");
    if (HomeSeoID !== null) formData.append("HomeSeoID", HomeSeoID);
    if (ContactSeoID !== null) formData.append("ContactSeoID", ContactSeoID);
    formData.append("UserId", userId);
    formData.append("SettingID", id);

    return axios.put(SETTING_UPDATE, formData, header)
        .then(res => res.data).catch(er => toast.error(er))
}

export function deleteSetting(id) {
    return axios.delete(`${SETTING_DELETE}/${id}`, header)
        .then(res => res.data).catch(er => toast.error(er))
}
// Setting END



// Slider
export function getAllSliders() {
    return axios.get(SLIDER_GETALL, header)
        .then(res => res.data).catch(er => toast.error(er))
}

export function getAllSlidersByGroup(id) {
    return axios.get(`${SLIDER_GETALLBYGROUP}/${id}`, header)
        .then(res => res.data).catch(er => toast.error(er))
}

export function getSlider(id) {
    return axios.get(`${SLIDER_GET}/${id}`, header)
        .then(res => res.data).catch(er => toast.error(er))
}

export function createSlider(file, title, titleen, desc, descen, url, urlen, AdditionalField1, AdditionalField1EN, AdditionalField2, AdditionalField2EN, AdditionalField3, AdditionalField3EN, SliderGroupID) {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("Title", title);
    formData.append("TitleEN", titleen);
    formData.append("Description", desc);
    formData.append("DescriptionEN", descen);
    formData.append("URL", url);
    formData.append("URLEN", urlen);
    formData.append("AdditionalField1", AdditionalField1);
    formData.append("AdditionalField1EN", AdditionalField1EN);
    formData.append("AdditionalField2", AdditionalField2);
    formData.append("AdditionalField2EN", AdditionalField2EN);
    formData.append("AdditionalField3", AdditionalField3);
    formData.append("AdditionalField3EN", AdditionalField3EN);
    formData.append("SliderGroupID", SliderGroupID);
    formData.append("UserId", userId);

    return axios.post(SLIDER_CREATE, formData, header)
        .then(res => res.data).catch(er => toast.error(er))
}

export function updateSlider(id, file, title, titleen, desc, descen, url, urlen, AdditionalField1, AdditionalField1EN, AdditionalField2, AdditionalField2EN, AdditionalField3, AdditionalField3EN, SliderGroupID) {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("Title", title);
    formData.append("TitleEN", titleen);
    formData.append("Description", desc);
    formData.append("DescriptionEN", descen);
    formData.append("URL", url);
    formData.append("URLEN", urlen);
    formData.append("AdditionalField1", AdditionalField1);
    formData.append("AdditionalField1EN", AdditionalField1EN);
    formData.append("AdditionalField2", AdditionalField2);
    formData.append("AdditionalField2EN", AdditionalField2EN);
    formData.append("AdditionalField3", AdditionalField3);
    formData.append("AdditionalField3EN", AdditionalField3EN);
    formData.append("SliderGroupID", SliderGroupID);
    formData.append("UserId", userId);
    formData.append("SliderID", id);

    return axios.put(SLIDER_UPDATE, formData, header)
        .then(res => res.data).catch(er => toast.error(er))
}

export function deleteSlider(id) {
    return axios.delete(`${SLIDER_DELETE}/${id}`, header)
        .then(res => res.data).catch(er => toast.error(er))
}
// Slider END



// SliderGroup
export function getAllSliderGroups() {
    return axios.get(SLIDERGROUP_GETALL, header)
        .then(res => res.data).catch(er => toast.error(er))
}

export function getSliderGroup(id) {
    return axios.get(`${SLIDERGROUP_GET}/${id}`, header)
        .then(res => res.data).catch(er => toast.error(er))
}

export function createSliderGroup(title, titleEN, description, descriptionEN, add1, add1en, add2, add2en, add3, add3en) {
    return axios.post(SLIDERGROUP_CREATE, { "title": title, "titleEN": titleEN, "description": description, "descriptionEN": descriptionEN, "additionalField1": add1, "additionalField1EN": add1en, "additionalField2": add2, "additionalField2EN": add2en, "additionalField3": add3, "additionalField3EN": add3en, "userId": userId }, header)
        .then(res => res.data).catch(er => toast.error(er))
}

export function updateSliderGroup(id, title, titleEN, description, descriptionEN, add1, add1en, add2, add2en, add3, add3en) {
    return axios.put(`${SLIDERGROUP_UPDATE}/${id}`, { "title": title, "titleEN": titleEN, "description": description, "descriptionEN": descriptionEN, "additionalField1": add1, "additionalField1EN": add1en, "additionalField2": add2, "additionalField2EN": add2en, "additionalField3": add3, "additionalField3EN": add3en, "userId": userId, "sliderGroupID": id }, header)
        .then(res => res.data).catch(er => toast.error(er))
}

export function deleteSliderGroup(id) {
    return axios.delete(`${SLIDERGROUP_DELETE}/${id}`, header)
        .then(res => res.data).catch(er => toast.error(er))
}
// SliderGroup END



// Social
export function getAllSocials() {
    return axios.get(SOCIAL_GETALL, header)
        .then(res => res.data).catch(er => toast.error(er))
}

export function getAllSocialsBySocial(id) {
    return axios.get(`${SOCIAL_GETALLBYSOCIAL}/${id}`, header)
        .then(res => res.data).catch(er => toast.error(er))
}

export function getSocial(id) {
    return axios.get(`${SOCIAL_GET}/${id}`, header)
        .then(res => res.data).catch(er => toast.error(er))
}

export function createSocial(file, title, url, socialMediaID) {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("Title", title);
    formData.append("URL", url);
    formData.append("SocialMediaID", socialMediaID);
    formData.append("UserId", userId);

    return axios.post(SOCIAL_CREATE, formData, header)
        .then(res => res.data).catch(er => toast.error(er))
}

export function updateSocial(id, file, title, url, socialMediaID) {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("Title", title);
    formData.append("URL", url);
    formData.append("SocialMediaID", socialMediaID);
    formData.append("UserId", userId);
    formData.append("SocialID", id);

    return axios.put(SOCIAL_UPDATE, formData, header)
        .then(res => res.data).catch(er => toast.error(er))
}

export function deleteSocial(id) {
    return axios.delete(`${SOCIAL_DELETE}/${id}`, header)
        .then(res => res.data).catch(er => toast.error(er))
}
// Social END




// SocialMedia
export function getAllSocialMedias() {
    return axios.get(SOCIALMEDIA_GETALL, header)
        .then(res => res.data).catch(er => toast.error(er))
}

export function getSocialMedia(id) {
    return axios.get(`${SOCIALMEDIA_GET}/${id}`, header)
        .then(res => res.data).catch(er => toast.error(er))
}

export function createSocialMedia(title, titleEN, description, descriptionEN) {
    return axios.post(SOCIALMEDIA_CREATE, { "title": title, "titleEN": titleEN, "description": description, "descriptionEN": descriptionEN, "userId": userId }, header)
        .then(res => res.data).catch(er => toast.error(er))
}

export function updateSocialMedia(id, title, titleEN, description, descriptionEN) {
    return axios.put(`${SOCIALMEDIA_UPDATE}/${id}`, { "title": title, "titleEN": titleEN, "description": description, "descriptionEN": descriptionEN, "userId": userId, "socialMediaID": id }, header)
        .then(res => res.data).catch(er => toast.error(er))
}

export function deleteSocialMedia(id) {
    return axios.delete(`${SOCIALMEDIA_DELETE}/${id}`, header)
        .then(res => res.data).catch(er => toast.error(er))
}
// SocialMedia END



// User
export function getAllUsers(search) {
    return axios.get(`${USER_GETALL}?searchTerm=${search}&pageNumber=1&pageSize=100`, header)
        .then(res => res.data).catch(er => toast.error(er))
}

export function getUser(id) {
    return axios.get(`${USER_GET}/${id}`, header)
        .then(res => res.data).catch(er => toast.error(er))
}

export function updateUser(id, firstName, lastName, userName, email, phoneNumber) {
    return axios.put(`${USER_UPDATE}/${id}`, { "firstName": firstName, "lastName": lastName, "userName": userName, "email": email, "phoneNumber": phoneNumber, "userId": id }, header)
        .then(res => res.data).catch(er => toast.error(er))
}

export function changePassword(id, currentPassword, newPassword) {
    return axios.put(`${USER_CHANGEPASSWORD}/${id}`, { "currentPassword": currentPassword, "newPassword": newPassword }, header)
        .then(res => res.data).catch(er => toast.error(er))
}

export function changePoint(id, point) {
    return axios.put(`${USER_CHANGEPASSWORD}/${id}`, { "userId": id, "point": point }, header)
        .then(res => res.data).catch(er => toast.error(er))
}

export function deleteUser(id) {
    return axios.delete(`${USER_DELETE}/${id}`, header)
        .then(res => res.data).catch(er => toast.error(er))
}

export function activeUser() {
    return axios.put(`${USER_ACTIVEUSERCHANGE}/${userId}/0`, {}, header)
        .then(res => res.data).catch(er => console.log("activeUser:", er))
}
// User END