import React, { useEffect, useState } from 'react';
import { Archive, Edit2, Home, PlusCircle, Trash2 } from 'react-feather';
import { Button, Card, CardBody, CardHeader, Col, Input, Row, Spinner } from 'reactstrap';
import { motion } from 'framer-motion';
import { Popconfirm, Table, Button as Button2, Tooltip, Select } from 'antd';
import { toast } from 'react-toastify';
import Header from '../../components/header';
import { createContact, deleteContact, getAllContacts, getAllSocialMedias, getContact, updateContact } from '../../core/request';
import { mobilVariant } from '../../components/animation';
import { useNavigate } from 'react-router-dom';
import { randomColor } from '../../components/general';
import { Helmet } from 'react-helmet';

const ContactPage = () => {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false)
    const [socialMedias, setSocialMedias] = useState([])
    const [formData, setFormData] = useState({ id: null, title: "", titleEn: "", desc: "", descEn: "", add1: "", add2: "", add3: "", add4: "", add5: "", socialMediaId: null })

    const clearForm = () => {
        setFormData({ id: null, title: "", titleEn: "", desc: "", descEn: "", add1: "", add2: "", add3: "", add4: "", add5: "", socialMediaId: null })
    }

    const getDatas = async () => {
        const contact = await getAllContacts();
        const social = await getAllSocialMedias();
        if (contact.message === "Success")
            setData(contact.result);

        if (social.message === "Success") {
            var newlist = []
            for (var i = 0; i < social.result.length; i++) {
                if (social.result[i] !== undefined)
                    newlist.push({ value: social.result[i].socialMediaID, label: social.result[i].title });
            }
            setSocialMedias(newlist);
        }

        setTimeout(() => {
            setLoading(true)
        }, 1000);
    }

    const getData = async (id) => {
        const data = await getContact(id);
        setFormData({ id: id, title: data.result.title, titleEn: data.result.titleEN, desc: data.result.description, descEn: data.result.descriptionEN, add1: data.result.addField1, add2: data.result.addField2, add3: data.result.addField3, add4: data.result.addField4, add5: data.result.addField5, socialMediaId: data.result.socialMediaID })
    }

    const addData = async () => {
        let res;
        if (formData.id) {
            res = await updateContact(formData.id, formData.title, formData.titleEn, formData.desc, formData.descEn, formData.add1, formData.add2, formData.add3, formData.add4, formData.add5, formData.socialMediaId);
        } else {
            res = await createContact(formData.title, formData.titleEn, formData.desc, formData.descEn, formData.add1, formData.add2, formData.add3, formData.add4, formData.add5, formData.socialMediaId);
        }
        if (res.message === "Success")
            toast.success("İşlem başarılı.")
        else
            toast.error("İşlem sırasında bir sorun oluştu!")
        clearForm()
        getDatas()
    }

    const deleteData = async (id) => {
        const form = await deleteContact(id);
        if (form.message === "Success")
            toast.success("İşlem başarılı.")
        else
            toast.error("İşlem sırasında bir sorun oluştu!")
        clearForm()
        getDatas()
    }

    const columns = [
        {
            title: "",
            key: "contactID",
            render: (e) => <div className='table-logo shadow' style={{ backgroundColor: randomColor() }}>{e.title[0]}</div>,
            width: 50
        },
        {
            title: "ID",
            key: "contactID",
            dataIndex: "contactID"
        },
        {
            title: "Başlık",
            key: "title",
            render: (e) => e.title
        },
        {
            title: "Description",
            key: "description",
            render: (e) => e.description
        },
        {
            title: "İşlemler",
            render: (e) => (
                <div className='d-flex justify-content-start'>
                    <div className='ml-2'>
                        <Tooltip title="Bayiler">
                            <Button2 className='border-success text-success' icon={<Archive size={20} className='text-success' />} onClick={() => navigate(`/contact/${e.contactID}`)} />
                        </Tooltip>
                    </div>
                    <div className='ml-2'>
                        <Tooltip title="Düzenle">
                            <Button2 className='border-warning text-warning' icon={<Edit2 size={20} className='text-warning' />} onClick={() => getData(e.contactID)} />
                        </Tooltip>
                    </div>
                    <div className='ml-2'>
                        <Popconfirm
                            title="Bu içeriği istediğinizden emin misiniz?"
                            onConfirm={() => deleteData(e.contactID)}
                            okText="Evet"
                            cancelText="Hayır"
                        >
                            <Tooltip title="Sil">
                                <Button2 icon={<Trash2 size={20} className='text-danger' />} danger />
                            </Tooltip>
                        </Popconfirm>
                    </div>
                </div>
            )
        }
    ];

    useEffect(() => {
        getDatas();
    }, []);

    return (
        <motion.div {...mobilVariant}>
            <Helmet>
                <title>Harvest We - İletişim Yönetimi</title>
            </Helmet>
            <Row className='w-100 pb-5'>
                <Col sm={3} md={3} xl={3}>
                    <Header />
                </Col>
                <Col sm={9} md={9} xl={9}>
                    <div className='right-content mb-5'>
                        <div className='d-flex justify-content-between'>
                            <div><h3>İletişim Yönetimi</h3></div>
                            <div className='d-flex justify-content-end'>
                                <Button onClick={() => navigate("/")} className='ml-2 bg-white border border-info text-info'><Home size={20} style={{ marginTop: -5 }} /> Dashboard</Button>
                            </div>
                        </div>
                        <Card className='shadow mt-3'>
                            <CardHeader className='pl-4 shadow-sm d-flex justify-content-between align-items-center bg-white p-3' tag='h4'>İletişim Grubu {formData.id ? "Güncelle" : "Ekle"}</CardHeader>
                            <CardBody>
                                <Row className='p-1'>
                                    <Col md={4} className='mb-3'>
                                        <span>Başlık</span>
                                        <Input type='input' value={formData.title} onChange={e => setFormData({ ...formData, title: e.target.value })} />
                                    </Col>
                                    <Col md={4} className='mb-3'>
                                        <span>Başlık (EN)</span>
                                        <Input type='input' value={formData.titleEn} onChange={e => setFormData({ ...formData, titleEn: e.target.value })} />
                                    </Col>
                                    <Col md={4} className='mb-3'>
                                        <span>Sosyal Medya</span>
                                        <Select
                                            options={socialMedias}
                                            className='w-100'
                                            style={{ height: 38 }}
                                            value={formData.socialMediaId}
                                            onChange={e => setFormData({ ...formData, socialMediaId: e })}
                                        />
                                    </Col>
                                    <Col md={6} className='mb-3'>
                                        <span>Açıklama</span>
                                        <Input type='input' value={formData.desc} onChange={e => setFormData({ ...formData, desc: e.target.value })} />
                                    </Col>
                                    <Col md={6} className='mb-3'>
                                        <span>Açıklama (EN)</span>
                                        <Input type='input' value={formData.descEn} onChange={e => setFormData({ ...formData, descEn: e.target.value })} />
                                    </Col>
                                    <Col md={12} className='mt-3 d-flex justify-content-end'>
                                        {formData.id ? <Button onClick={() => clearForm()} className='bg-white border-warning text-warning'>Temizle</Button> : null}
                                        <Button
                                            color={formData.id ? 'warning' : 'success'}
                                            onClick={() => addData()}
                                        >
                                            <PlusCircle size={20} style={{ marginTop: -3, marginRight: 7 }} />{formData.id ? "Güncelle" : "Ekle"}
                                        </Button>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        <Card className='shadow mt-4'>
                            <CardHeader className='pl-4 d-flex shadow-sm justify-content-between align-items-center bg-white p-3'>
                                <div><h4>İletişim Grup Listesi</h4></div>
                            </CardHeader>
                            <CardBody>
                                {loading ? <Table
                                    rowKey='contactID'
                                    dataSource={data}
                                    columns={columns}
                                    scroll={{ x: 'max-content' }}
                                /> : <div className='w-100 mt-5 mb-5 d-flex justify-content-center align-items-center'>
                                    <Spinner style={{ width: '3rem', height: '3rem' }} color='info'> </Spinner>
                                </div>}
                            </CardBody>
                        </Card>
                    </div>
                </Col>
            </Row>
        </motion.div>
    );
};

export default ContactPage;
