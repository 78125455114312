import React from 'react'
import { AnimatePresence } from "framer-motion";
import { Route, Routes, useLocation } from 'react-router-dom';
import LoginPage from '../pages/auth'
import FormPage from '../pages/form';
import FormResponsePage from '../pages/form/formResponse';
import SettingPage from '../pages/setting';
import UserPage from '../pages/user';
import SocialsPage from '../pages/socialMedia/socials';
import SocialMediaPage from '../pages/socialMedia';
import SlidersPage from '../pages/slider/sliders';
import SliderPage from '../pages/slider';
import SeoPage from '../pages/seo';
import ProductGroupPage from '../pages/product';
import ProductPage from '../pages/product/products';
import HeaderPage from '../pages/header';
import ContactPage from '../pages/contact';
import DealerPage from '../pages/contact/dealer';
import DocumentPage from '../pages/document';
import DocumentsPage from '../pages/document/documents';
import PagePage from '../pages/page';

const AnimatedRoutes = () => {
    const location = useLocation()
    const token = localStorage.getItem("auth") === null ? null : JSON.parse(localStorage.getItem("auth")).accessToken

    return (
        <AnimatePresence>
            <Routes key={location.pathname} location={location}>
                <Route path='/' element={token !== null ? <HeaderPage /> : <LoginPage />} />
                <Route path='/login' element={token === null ? <LoginPage /> : <FormPage />} />
                <Route path='/form' element={token !== null ? <FormPage /> : <LoginPage />} />
                <Route path='/form/:id' element={token !== null ? <FormResponsePage /> : <LoginPage />} />
                <Route path='/contact' element={token !== null ? <ContactPage /> : <LoginPage />} />
                <Route path='/contact/:id' element={token !== null ? <DealerPage /> : <LoginPage />} />
                <Route path='/social-media' element={token !== null ? <SocialMediaPage /> : <LoginPage />} />
                <Route path='/social-media/:id' element={token !== null ? <SocialsPage /> : <LoginPage />} />
                <Route path='/slider-group' element={token !== null ? <SliderPage /> : <LoginPage />} />
                <Route path='/slider-group/:id' element={token !== null ? <SlidersPage /> : <LoginPage />} />
                <Route path='/product-group' element={token !== null ? <ProductGroupPage /> : <LoginPage />} />
                <Route path='/product-group/:id' element={token !== null ? <ProductPage /> : <LoginPage />} />
                <Route path='/document-group' element={token !== null ? <DocumentPage /> : <LoginPage />} />
                <Route path='/document-group/:id' element={token !== null ? <DocumentsPage /> : <LoginPage />} />
                <Route path='/seo' element={token !== null ? <SeoPage /> : <LoginPage />} />
                <Route path='/page' element={token !== null ? <PagePage /> : <LoginPage />} />
                <Route path='/header' element={token !== null ? <HeaderPage /> : <LoginPage />} />
                <Route path='/setting' element={token !== null ? <SettingPage /> : <LoginPage />} />
                <Route path='/user' element={token !== null ? <UserPage /> : <LoginPage />} />
            </Routes>
        </AnimatePresence>
    )
}

export default AnimatedRoutes