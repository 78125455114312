import React, { useEffect, useState } from 'react';
import { Edit2, Grid, Home, PlusCircle, Trash2 } from 'react-feather';
import { Button, Card, CardBody, CardHeader, Col, Input, Row, Spinner } from 'reactstrap';
import { motion } from 'framer-motion';
import { Popconfirm, Table, Button as Button2, Tooltip, Select } from 'antd';
import { toast } from 'react-toastify';
import Header from '../../components/header';
import { createProductGroup, deleteProductGroup, getAllDocumentGroups, getAllHeaders, getAllProductGroups, getAllSeos, getProductGroup, updateProductGroup } from '../../core/request';
import { mobilVariant } from '../../components/animation';
import { useNavigate } from 'react-router-dom';
import { randomColor } from '../../components/general';
import { Helmet } from 'react-helmet';
import { url2 } from '../../api';
import { CKEditor } from 'ckeditor4-react';

const ProductGroupPage = () => {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [content, setContent] = useState(null)
    const [contentEn, setContentEn] = useState(null)
    const [mailText, setMailText] = useState(null)
    const [mailTextEn, setMailTextEn] = useState(null)
    const [seos, setSeos] = useState([])
    const [documents, setDocuments] = useState([])
    const [headers, setHeaders] = useState([]);
    const [loading, setLoading] = useState(false)
    const [formData, setFormData] = useState({ id: null, file: null, file2: null, file3: null, filePath: "", filePath2: "", filePath3: "", title: "", titleEn: "", desc: "", descEn: "", content: "", contentEN: "", add1: "", add1En: "", add2: "", add2En: "", add3: "", add3En: "", seoId: null, headerId: null, documentGroupId: null })

    const clearForm = () => {
        setContent(null)
        setContentEn(null)
        setMailText(null)
        setMailTextEn(null)
        setFormData({ id: null, file: null, file2: null, file3: null, filePath: "", filePath2: "", filePath3: "", title: "", titleEn: "", desc: "", descEn: "", content: "", contentEN: "", add1: "", add1En: "", add2: "", add2En: "", add3: "", add3En: "", seoId: null, headerId: null, documentGroupId: null })
    }

    const getDatas = async () => {
        const productGroup = await getAllProductGroups();
        const documents = await getAllDocumentGroups()
        const seos = await getAllSeos();
        const categories = await getAllHeaders();
        if (categories.message === "Success") {
            var catList = []
            var catList2 = []
            for (var i = 0; i < categories.result.length; i++) {
                catList.push({ label: categories.result[i].title, value: categories.result[i].headerID })
                catList2.push(categories.result[i])
                if (categories.result[i].subHeaders !== undefined && categories.result[i].subHeaders.length > 0) {
                    for (var j = 0; j < categories.result[i].subHeaders.length; j++) {
                        catList.push({ label: categories.result[i].subHeaders[j].title, value: categories.result[i].subHeaders[j].headerID })
                        catList2.push(categories.result[i].subHeaders[j])
                    }
                }
            }
            setHeaders(catList)
        }

        if (documents.message === "Success") {
            var newlist = []
            for (var i = 0; i < documents.result.length; i++) {
                if (documents.result[i] !== undefined)
                    newlist.push({ value: documents.result[i].documentGroupID, label: documents.result[i].title });
            }
            setDocuments(newlist);
        }

        if (seos.message === "Success") {
            var newlist = []
            for (var i = 0; i < seos.result.length; i++) {
                if (seos.result[i] !== undefined)
                    newlist.push({ value: seos.result[i].seoID, label: seos.result[i].title });
            }
            setSeos(newlist);
        }

        if (productGroup.message === "Success")
            setData(productGroup.result);

        setTimeout(() => {
            setLoading(true)
        }, 1000);
    }

    const handleEditorChange = (event) => {
        var data = event.editor.getData()
        setContent(data)
    };

    const handleEditorChangeEN = (event) => {
        var data = event.editor.getData()
        setContentEn(data)
    };

    const handleEditorMailChange = (event) => {
        var data = event.editor.getData()
        setMailText(data)
    };

    const handleEditorMailChangeEN = (event) => {
        var data = event.editor.getData()
        setMailTextEn(data)
    };

    const getData = async (id) => {
        const data = await getProductGroup(id);
        setContent(data.result.content)
        setContentEn(data.result.contentEN)
        setMailText(data.result.additionalField1)
        setMailTextEn(data.result.additionalField1EN)
        setFormData({ id: id, filePath: data.result.fileFullPath, filePath2: data.result.fileFullPath2, filePath3: data.result.fileFullPath3, title: data.result.title, titleEn: data.result.titleEN, desc: data.result.description, descEn: data.result.descriptionEN, content: data.result.content, contentEN: data.result.contentEN, add1: data.result.additionalField1, add1En: data.result.additionalField1EN, add2: data.result.additionalField2, add2En: data.result.additionalField2EN, add3: data.result.additionalField3, add3En: data.result.additionalField3EN, seoId: data.result.seoID, headerId: data.result.headerID, documentGroupId: data.result.documentGroupID })
    }

    const addData = async () => {
        let res;
        if (formData.id) {
            res = await updateProductGroup(formData.id, formData.file, formData.file2, formData.file3, formData.title, formData.titleEn, formData.desc, formData.descEn, content, contentEn, mailText, mailTextEn, formData.add2, formData.add2En, formData.add3, formData.add3En, formData.seoId, formData.headerId, formData.documentGroupId);
        } else {
            res = await createProductGroup(formData.file, formData.file2, formData.file3, formData.title, formData.titleEn, formData.desc, formData.descEn, content, contentEn, mailText, mailTextEn, formData.add2, formData.add2En, formData.add3, formData.add3En, formData.seoId, formData.headerId, formData.documentGroupId);
        }
        if (res.message === "Success")
            toast.success("İşlem başarılı.")
        else
            toast.error("İşlem sırasında bir sorun oluştu!")
        clearForm()
        getDatas()
    }

    const deleteData = async (id) => {
        const form = await deleteProductGroup(id);
        if (form.message === "Success")
            toast.success("İşlem başarılı.")
        else
            toast.error("İşlem sırasında bir sorun oluştu!")
        clearForm()
        getDatas()
    }

    const columns = [
        {
            title: "",
            key: "productGroupID",
            render: (e) => e.fileFullPath2 ? <div><img src={`${url2}${e.fileFullPath2}`} alt='' style={{ height: 36, width: 36, borderRadius: 18 }} /></div> : <div className='table-logo shadow' style={{ backgroundColor: randomColor() }}>{e.title[0]}</div>,
            width: 50
        },
        {
            title: "ID",
            key: "productGroupID",
            dataIndex: "productGroupID"
        },
        {
            title: "Başlık",
            key: "title",
            render: (e) => e.title
        },
        {
            title: "Description",
            key: "description",
            render: (e) => e.description
        },
        {
            title: "İşlemler",
            render: (e) => (
                <div className='d-flex justify-content-start'>
                    <div className='ml-2'>
                        <Tooltip title="Ürünler">
                            <Button2 className='border-success text-success' icon={<Grid size={20} className='text-success' />} onClick={() => navigate(`/product-group/${e.productGroupID}`)} />
                        </Tooltip>
                    </div>
                    <div className='ml-2'>
                        <Tooltip title="Düzenle">
                            <Button2 className='border-warning text-warning' icon={<Edit2 size={20} className='text-warning' />} onClick={() => getData(e.productGroupID)} />
                        </Tooltip>
                    </div>
                    <div className='ml-2'>
                        <Popconfirm
                            title="Bu içeriği istediğinizden emin misiniz?"
                            onConfirm={() => deleteData(e.productGroupID)}
                            okText="Evet"
                            cancelText="Hayır"
                        >
                            <Tooltip title="Sil">
                                <Button2 icon={<Trash2 size={20} className='text-danger' />} danger />
                            </Tooltip>
                        </Popconfirm>
                    </div>
                </div>
            )
        }
    ];

    useEffect(() => {
        getDatas();
    }, []);

    return (
        <motion.div {...mobilVariant}>
            <Helmet>
                <title>Harvest We - Ürün Grubu Yönetimi</title>
            </Helmet>
            <Row className='w-100 pb-5'>
                <Col sm={3} md={3} xl={3}>
                    <Header />
                </Col>
                <Col sm={9} md={9} xl={9}>
                    <div className='right-content mb-5'>
                        <div className='d-flex justify-content-between'>
                            <div><h3>Ürün Grup Yönetimi</h3></div>
                            <div className='d-flex justify-content-end'>
                                <Button onClick={() => navigate("/")} className='ml-2 bg-white border border-info text-info'><Home size={20} style={{ marginTop: -5 }} /> Dashboard</Button>
                            </div>
                        </div>
                        <Card className='shadow mt-3'>
                            <CardHeader className='pl-4 shadow-sm d-flex justify-content-between align-items-center bg-white p-3' tag='h4'>Ürün Grubu {formData.id ? "Güncelle" : "Ekle"}</CardHeader>
                            <CardBody>
                                <Row className='p-1'>
                                    <Col md={12} className='mb-3 d-flex justify-content-center align-items-center'>
                                        <div>
                                            {formData.file || formData.filePath ? <div className={formData.file ? 'd-flex mb-4 justify-content-center' : 'd-flex mb-1 justify-content-center'}>
                                                <img src={formData.file ? URL.createObjectURL(formData.file) : `${url2}${formData.filePath}`} alt='Resim ' style={{ height: 60, width: 100, objectFit: 'cover', borderRadius: 10 }} />
                                            </div> : <div className='d-flex justify-content-center'>
                                                <div className='p-2 d-flex align-items-center justify-content-center rounded border border-secondary mb-2' style={{ width: 150, height: 60 }}>Banner Yok!</div>
                                            </div>}
                                        </div>
                                        <div className='ml-2'>
                                            {formData.file2 || formData.filePath2 ? <div className={formData.file2 ? 'd-flex mb-4 justify-content-center' : 'd-flex mb-1 justify-content-center'}>
                                                <img src={formData.file2 ? URL.createObjectURL(formData.file2) : `${url2}${formData.filePath2}`} alt='Resim ' style={{ height: 60, width: 100, objectFit: 'cover', borderRadius: 10 }} />
                                            </div> : <div className='d-flex justify-content-center'>
                                                <div className='p-2 d-flex align-items-center justify-content-center rounded border border-secondary mb-2' style={{ width: 150, height: 60 }}>Logo Yok!</div>
                                            </div>}
                                        </div>
                                        <div className='ml-2'>
                                            {formData.file3 || formData.filePath3 ? <div className={formData.file3 ? 'd-flex mb-4 justify-content-center' : 'd-flex mb-1 justify-content-center'}>
                                                <img src={formData.file3 ? URL.createObjectURL(formData.file3) : `${url2}${formData.filePath3}`} alt='Resim ' style={{ height: 60, width: 100, objectFit: 'cover', borderRadius: 10 }} />
                                            </div> : <div className='d-flex justify-content-center'>
                                                <div className='p-2 d-flex align-items-center justify-content-center rounded border border-secondary mb-2' style={{ width: 150, height: 60 }}>Orta Resim Yok!</div>
                                            </div>}
                                        </div>
                                    </Col>
                                    <Col md={4} className='mb-3'>
                                        <span>Logo</span>
                                        <Input type='file' onChange={e => setFormData({ ...formData, file2: e.target.files[0] })} />
                                    </Col>
                                    <Col md={4} className='mb-3'>
                                        <span>Banner</span>
                                        <Input type='file' onChange={e => setFormData({ ...formData, file: e.target.files[0] })} />
                                    </Col>
                                    <Col md={4} className='mb-3'>
                                        <span>Orta Resim</span>
                                        <Input type='file' onChange={e => setFormData({ ...formData, file3: e.target.files[0] })} />
                                    </Col>
                                    <Col md={3} className='mb-3'>
                                        <span>Başlık</span>
                                        <Input type='input' value={formData.title} onChange={e => setFormData({ ...formData, title: e.target.value })} />
                                    </Col>
                                    <Col md={3} className='mb-3'>
                                        <span>Başlık (EN)</span>
                                        <Input type='input' value={formData.titleEn} onChange={e => setFormData({ ...formData, titleEn: e.target.value })} />
                                    </Col>
                                    <Col md={3} className='mb-3'>
                                        <span>Seo</span>
                                        <Select
                                            options={seos}
                                            className='w-100'
                                            style={{ height: 38 }}
                                            value={formData.seoId}
                                            onChange={e => setFormData({ ...formData, seoId: e })}
                                        />
                                    </Col>
                                    <Col md={3} className='mb-3'>
                                        <span>Başlık</span>
                                        <Select
                                            options={headers}
                                            className='w-100'
                                            style={{ height: 38 }}
                                            value={formData.headerId}
                                            onChange={e => setFormData({ ...formData, headerId: e })}
                                        />
                                    </Col>
                                    <Col md={4} className='mb-3'>
                                        <span>Döküman</span>
                                        <Select
                                            options={headers}
                                            className='w-100'
                                            style={{ height: 38 }}
                                            value={formData.documentGroupId}
                                            onChange={e => setFormData({ ...formData, documentGroupId: e })}
                                        />
                                    </Col>
                                    <Col md={4} className='mb-3'>
                                        <span>Açıklama</span>
                                        <Input type='input' value={formData.desc} onChange={e => setFormData({ ...formData, desc: e.target.value })} />
                                    </Col>
                                    <Col md={4} className='mb-3'>
                                        <span>Açıklama (EN)</span>
                                        <Input type='input' value={formData.descEn} onChange={e => setFormData({ ...formData, descEn: e.target.value })} />
                                    </Col>
                                    <Col md={6} className='mb-3'>
                                        <span>Banner Metni</span>
                                        <Input type='input' value={formData.add2} onChange={e => setFormData({ ...formData, add2: e.target.value })} />
                                    </Col>
                                    <Col md={6} className='mb-3'>
                                        <span>Banner Metni (EN)</span>
                                        <Input type='input' value={formData.add2En} onChange={e => setFormData({ ...formData, add2En: e.target.value })} />
                                    </Col>
                                    <Col md={12} className='mb-3'>
                                        <span>İçerik</span>
                                        <CKEditor
                                            key={formData.id === 0 ? null : formData.id}
                                            editorUrl="https://cdn.ckeditor.com/4.16.0/standard/ckeditor.js"
                                            data={content}
                                            initData={content}
                                            onChange={handleEditorChange}
                                            onInstanceReady={(event) => {
                                                const editor = event.editor;
                                                editor.on('mode', () => {
                                                    if (editor.mode === 'source') {
                                                        const intervalId = setInterval(() => {
                                                            const data = editor.getData();
                                                            setContent(data)
                                                        }, 1000);

                                                        editor.on('mode', () => {
                                                            if (editor.mode !== 'source') {
                                                                clearInterval(intervalId);
                                                            }
                                                        });
                                                    }
                                                });
                                            }}
                                            config={{
                                                toolbar: [
                                                    ['Source', '-', 'Save', 'NewPage', 'Preview', 'Print', '-', 'Templates'],
                                                    ['Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', '-', 'Undo', 'Redo'],
                                                    ['Find', 'Replace', '-', 'SelectAll', '-', 'Scayt'],
                                                    ['Form', 'Checkbox', 'Radio', 'TextField', 'Textarea', 'Select', 'Button', 'ImageButton', 'HiddenField'],
                                                    '/',
                                                    ['Bold', 'Italic', 'Underline', 'Strike', '-', 'TextColor', 'BGColor'],
                                                    ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote', 'CreateDiv'],
                                                    ['JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock'],
                                                    ['BidiLtr', 'BidiRtl'],
                                                    ['Link', 'Unlink', 'Anchor'],
                                                    ['Image', 'Flash', 'Table', 'HorizontalRule', 'Smiley', 'SpecialChar', 'PageBreak', 'Iframe'],
                                                    '/',
                                                    ['Styles', 'Format', 'Font', 'FontSize'],
                                                    ['Maximize', 'ShowBlocks'],
                                                    ['About']
                                                ],
                                                extraPlugins: 'colorbutton,font,justify,bidi,forms,div,find,flash,iframe,pagebreak,smiley,scayt',
                                                removePlugins: 'exportpdf',
                                                allowedContent: true
                                            }}
                                        />
                                    </Col>
                                    <Col md={12} className='mb-3'>
                                        <span>İçerik EN</span>
                                        <CKEditor
                                            key={formData.id === 0 ? null : formData.id}
                                            editorUrl="https://cdn.ckeditor.com/4.16.0/standard/ckeditor.js"
                                            data={contentEn}
                                            initData={contentEn}
                                            onChange={handleEditorChangeEN}
                                            onInstanceReady={(event) => {
                                                const editor = event.editor;
                                                editor.on('mode', () => {
                                                    if (editor.mode === 'source') {
                                                        const intervalId = setInterval(() => {
                                                            const data = editor.getData();
                                                            setContentEn(data)
                                                        }, 1000);

                                                        editor.on('mode', () => {
                                                            if (editor.mode !== 'source') {
                                                                clearInterval(intervalId);
                                                            }
                                                        });
                                                    }
                                                });
                                            }}
                                            config={{
                                                toolbar: [
                                                    ['Source', '-', 'Save', 'NewPage', 'Preview', 'Print', '-', 'Templates'],
                                                    ['Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', '-', 'Undo', 'Redo'],
                                                    ['Find', 'Replace', '-', 'SelectAll', '-', 'Scayt'],
                                                    ['Form', 'Checkbox', 'Radio', 'TextField', 'Textarea', 'Select', 'Button', 'ImageButton', 'HiddenField'],
                                                    '/',
                                                    ['Bold', 'Italic', 'Underline', 'Strike', '-', 'TextColor', 'BGColor'],
                                                    ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote', 'CreateDiv'],
                                                    ['JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock'],
                                                    ['BidiLtr', 'BidiRtl'],
                                                    ['Link', 'Unlink', 'Anchor'],
                                                    ['Image', 'Flash', 'Table', 'HorizontalRule', 'Smiley', 'SpecialChar', 'PageBreak', 'Iframe'],
                                                    '/',
                                                    ['Styles', 'Format', 'Font', 'FontSize'],
                                                    ['Maximize', 'ShowBlocks'],
                                                    ['About']
                                                ],
                                                extraPlugins: 'colorbutton,font,justify,bidi,forms,div,find,flash,iframe,pagebreak,smiley,scayt',
                                                removePlugins: 'exportpdf',
                                                allowedContent: true
                                            }}
                                        />
                                    </Col>
                                    <Col md={12} className='mb-3'>
                                        <span>Mail Metni</span>
                                        <CKEditor
                                            key={formData.id === 0 ? null : formData.id}
                                            editorUrl="https://cdn.ckeditor.com/4.16.0/standard/ckeditor.js"
                                            data={mailText}
                                            initData={mailText}
                                            onChange={handleEditorMailChange}
                                            onInstanceReady={(event) => {
                                                const editor = event.editor;
                                                editor.on('mode', () => {
                                                    if (editor.mode === 'source') {
                                                        const intervalId = setInterval(() => {
                                                            const data = editor.getData();
                                                            setMailText(data)
                                                        }, 1000);

                                                        editor.on('mode', () => {
                                                            if (editor.mode !== 'source') {
                                                                clearInterval(intervalId);
                                                            }
                                                        });
                                                    }
                                                });
                                            }}
                                            config={{
                                                toolbar: [
                                                    ['Source', '-', 'Save', 'NewPage', 'Preview', 'Print', '-', 'Templates'],
                                                    ['Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', '-', 'Undo', 'Redo'],
                                                    ['Find', 'Replace', '-', 'SelectAll', '-', 'Scayt'],
                                                    ['Form', 'Checkbox', 'Radio', 'TextField', 'Textarea', 'Select', 'Button', 'ImageButton', 'HiddenField'],
                                                    '/',
                                                    ['Bold', 'Italic', 'Underline', 'Strike', '-', 'TextColor', 'BGColor'],
                                                    ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote', 'CreateDiv'],
                                                    ['JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock'],
                                                    ['BidiLtr', 'BidiRtl'],
                                                    ['Link', 'Unlink', 'Anchor'],
                                                    ['Image', 'Flash', 'Table', 'HorizontalRule', 'Smiley', 'SpecialChar', 'PageBreak', 'Iframe'],
                                                    '/',
                                                    ['Styles', 'Format', 'Font', 'FontSize'],
                                                    ['Maximize', 'ShowBlocks'],
                                                    ['About']
                                                ],
                                                extraPlugins: 'colorbutton,font,justify,bidi,forms,div,find,flash,iframe,pagebreak,smiley,scayt',
                                                removePlugins: 'exportpdf',
                                                allowedContent: true
                                            }}
                                        />
                                    </Col>
                                    <Col md={12} className='mb-3'>
                                        <span>Mail Metni (EN)</span>
                                        <CKEditor
                                            key={formData.id === 0 ? null : formData.id}
                                            editorUrl="https://cdn.ckeditor.com/4.16.0/standard/ckeditor.js"
                                            data={mailTextEn}
                                            initData={mailTextEn}
                                            onChange={handleEditorMailChangeEN}
                                            onInstanceReady={(event) => {
                                                const editor = event.editor;
                                                editor.on('mode', () => {
                                                    if (editor.mode === 'source') {
                                                        const intervalId = setInterval(() => {
                                                            const data = editor.getData();
                                                            setMailTextEn(data)
                                                        }, 1000);

                                                        editor.on('mode', () => {
                                                            if (editor.mode !== 'source') {
                                                                clearInterval(intervalId);
                                                            }
                                                        });
                                                    }
                                                });
                                            }}
                                            config={{
                                                toolbar: [
                                                    ['Source', '-', 'Save', 'NewPage', 'Preview', 'Print', '-', 'Templates'],
                                                    ['Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', '-', 'Undo', 'Redo'],
                                                    ['Find', 'Replace', '-', 'SelectAll', '-', 'Scayt'],
                                                    ['Form', 'Checkbox', 'Radio', 'TextField', 'Textarea', 'Select', 'Button', 'ImageButton', 'HiddenField'],
                                                    '/',
                                                    ['Bold', 'Italic', 'Underline', 'Strike', '-', 'TextColor', 'BGColor'],
                                                    ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote', 'CreateDiv'],
                                                    ['JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock'],
                                                    ['BidiLtr', 'BidiRtl'],
                                                    ['Link', 'Unlink', 'Anchor'],
                                                    ['Image', 'Flash', 'Table', 'HorizontalRule', 'Smiley', 'SpecialChar', 'PageBreak', 'Iframe'],
                                                    '/',
                                                    ['Styles', 'Format', 'Font', 'FontSize'],
                                                    ['Maximize', 'ShowBlocks'],
                                                    ['About']
                                                ],
                                                extraPlugins: 'colorbutton,font,justify,bidi,forms,div,find,flash,iframe,pagebreak,smiley,scayt',
                                                removePlugins: 'exportpdf',
                                                allowedContent: true
                                            }}
                                        />
                                    </Col>
                                    <Col md={12} className='mt-3 d-flex justify-content-end'>
                                        {formData.id ? <Button onClick={() => clearForm()} className='bg-white border-warning text-warning'>Temizle</Button> : null}
                                        <Button
                                            color={formData.id ? 'warning' : 'success'}
                                            onClick={() => addData()}
                                            className='text-white'
                                        >
                                            <PlusCircle size={20} style={{ marginTop: -3, marginRight: 7 }} />{formData.id ? "Güncelle" : "Ekle"}
                                        </Button>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        <Card className='shadow mt-4'>
                            <CardHeader className='pl-4 d-flex shadow-sm justify-content-between align-items-center bg-white p-3'>
                                <div><h4>Ürün Grup Listesi</h4></div>
                            </CardHeader>
                            <CardBody>
                                {loading ? <Table
                                    rowKey='productGroupID'
                                    dataSource={data}
                                    columns={columns}
                                    scroll={{ x: 'max-content' }}
                                /> : <div className='w-100 mt-5 mb-5 d-flex justify-content-center align-items-center'>
                                    <Spinner style={{ width: '3rem', height: '3rem' }} color='info'> </Spinner>
                                </div>}
                            </CardBody>
                        </Card>
                    </div>
                </Col>
            </Row>
        </motion.div>
    );
};

export default ProductGroupPage;
