import React, { useEffect, useState } from 'react';
import { Edit2, Home, MessageCircle, PlusCircle, Trash2 } from 'react-feather';
import { Button, Card, CardBody, CardHeader, Col, Input, Row, Spinner } from 'reactstrap';
import { motion } from 'framer-motion';
import { Popconfirm, Table, Button as Button2, Tooltip } from 'antd';
import { toast } from 'react-toastify';
import Header from '../../components/header';
import { createForm, deleteForm, getAllForms, getForm, updateForm } from '../../core/request';
import { mobilVariant } from '../../components/animation';
import { useNavigate } from 'react-router-dom';
import { randomColor } from '../../components/general';
import { Helmet } from 'react-helmet';

const FormPage = () => {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [selectedData, setSelectedData] = useState(null)
    const [loading, setLoading] = useState(false)
    const [formData, setFormData] = useState({
        title: "",
        titleEn: "",
        desc: "",
        descEn: ""
    })

    const clearForm = () => {
        setFormData({ title: "", titleEn: "", desc: "", descEn: "" })
        setSelectedData(null)
    }

    const getDatas = async () => {
        const forms = await getAllForms();
        if (forms.message === "Success")
            setData(forms.result);

        setTimeout(() => {
            setLoading(true)
        }, 1000);
    }

    const getData = async (id) => {
        const form = await getForm(id);
        setSelectedData(id)
        setFormData({ title: form.result.title, titleEn: form.result.titleEN, desc: form.result.description, descEn: form.result.descriptionEN })
    }

    const addData = async () => {
        let res;
        if (selectedData) {
            res = await updateForm(selectedData, formData.title, formData.titleEn, formData.desc, formData.descEn);
        } else {
            res = await createForm(formData.title, formData.titleEn, formData.desc, formData.descEn);
        }
        if (res.message === "Success")
            toast.success("İşlem başarılı.")
        else
            toast.error("İşlem sırasında bir sorun oluştu!")
        clearForm()
        getDatas()
    }

    const deleteData = async (id) => {
        const form = await deleteForm(id);
        if (form.message === "Success")
            toast.success("İşlem başarılı.")
        else
            toast.error("İşlem sırasında bir sorun oluştu!")
        clearForm()
        getDatas()
    }

    const columns = [
        {
            title: "",
            key: "formID",
            render: (e) => <div className='table-logo shadow' style={{ backgroundColor: randomColor() }}>{e.title[0]}</div>,
            width: 50
        },
        {
            title: "ID",
            key: "formID",
            dataIndex: "formID"
        },
        {
            title: "Başlık",
            key: "title",
            render: (e) => e.title
        },
        {
            title: "Description",
            key: "description",
            render: (e) => e.description
        },
        {
            title: "İşlemler",
            render: (e) => (
                <div className='d-flex justify-content-start'>
                    <div className='ml-2'>
                        <Tooltip title="Form Cevapları">
                            <Button2 className='border-success text-success' icon={<MessageCircle size={20} className='text-success' />} onClick={() => navigate(`/form/${e.formID}`)} />
                        </Tooltip>
                    </div>
                    <div className='ml-2'>
                        <Tooltip title="Form Düzenle">
                            <Button2 className='border-warning text-warning' icon={<Edit2 size={20} className='text-warning' />} onClick={() => getData(e.formID)} />
                        </Tooltip>
                    </div>
                    <div className='ml-2'>
                        <Popconfirm
                            title="Bu form içeriğini silmek istediğinizden emin misiniz?"
                            onConfirm={() => deleteData(e.formID)}
                            okText="Evet"
                            cancelText="Hayır"
                        >
                            <Tooltip title="Form Sil">
                                <Button2 icon={<Trash2 size={20} className='text-danger' />} danger />
                            </Tooltip>
                        </Popconfirm>
                    </div>
                </div>
            )
        }
    ];

    useEffect(() => {
        getDatas();
    }, []);

    return (
        <motion.div {...mobilVariant}>
            <Helmet>
                <title>Harvest We - Form Yönetimi</title>
            </Helmet>
            <Row className='w-100 pb-5'>
                <Col sm={3} md={3} xl={3}>
                    <Header />
                </Col>
                <Col sm={9} md={9} xl={9}>
                    <div className='right-content mb-5'>
                        <div className='d-flex justify-content-between'>
                            <div><h3>Form Yönetimi</h3></div>
                            <div className='d-flex justify-content-end'>
                                <Button onClick={() => navigate("/")} className='ml-2 bg-white border border-info text-info'><Home size={20} style={{ marginTop: -5 }} /> Dashboard</Button>
                            </div>
                        </div>
                        <Card className='shadow mt-3'>
                            <CardHeader className='pl-4 shadow-sm d-flex justify-content-between align-items-center bg-white p-3' tag='h4'>Form Ekle</CardHeader>
                            <CardBody>
                                <Row className='p-1'>
                                    <Col md={6} className='mb-3'>
                                        <span>Başlık</span>
                                        <Input type='input' value={formData.title} onChange={e => setFormData({ ...formData, title: e.target.value })} />
                                    </Col>
                                    <Col md={6} className='mb-3'>
                                        <span>Başlık (EN)</span>
                                        <Input type='input' value={formData.titleEn} onChange={e => setFormData({ ...formData, titleEn: e.target.value })} />
                                    </Col>
                                    <Col md={6} className='mb-3'>
                                        <span>Açıklama</span>
                                        <Input type='input' value={formData.desc} onChange={e => setFormData({ ...formData, desc: e.target.value })} />
                                    </Col>
                                    <Col md={6} className='mb-3'>
                                        <span>Açıklama (EN)</span>
                                        <Input type='input' value={formData.descEn} onChange={e => setFormData({ ...formData, descEn: e.target.value })} />
                                    </Col>
                                    <Col md={12} className='mt-3 d-flex justify-content-end'>
                                        {selectedData ? <Button onClick={() => clearForm()} className='bg-white border-warning text-warning'>Temizle</Button> : null}
                                        <Button
                                            color={selectedData ? 'warning' : 'success'}
                                            onClick={() => addData()}
                                        >
                                            <PlusCircle size={20} style={{ marginTop: -3, marginRight: 7 }} />{selectedData ? "Güncelle" : "Ekle"}
                                        </Button>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        <Card className='shadow mt-4'>
                            <CardHeader className='pl-4 d-flex shadow-sm justify-content-between align-items-center bg-white p-3'>
                                <div><h4>Form Listesi</h4></div>
                            </CardHeader>
                            <CardBody>
                                {loading ? <Table
                                    rowKey='formID'
                                    dataSource={data}
                                    columns={columns}
                                    scroll={{ x: 'max-content' }}
                                /> : <div className='w-100 mt-5 mb-5 d-flex justify-content-center align-items-center'>
                                    <Spinner style={{ width: '3rem', height: '3rem' }} color='info'> </Spinner>
                                </div>}
                            </CardBody>
                        </Card>
                    </div>
                </Col>
            </Row>
        </motion.div>
    );
};

export default FormPage;
