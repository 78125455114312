export const toSeoUrl = (url) => {
    return url.toString()               // Convert to string
        .normalize('NFD')               // Change diacritics
        .replace(/[\u0300-\u036f]/g, '') // Remove illegal characters
        .replace(/\s+/g, '-')            // Change whitespace to dashes
        .toLowerCase()                  // Change to lowercase
        .replace(/&/g, '-and-')          // Replace ampersand
        .replace(/[^a-z0-9\-]/g, '')     // Remove anything that is not a letter, number or dash
        .replace(/-+/g, '-')             // Remove duplicate dashes
        .replace(/^-*/, '')              // Remove starting dashes
        .replace(/-*$/, '');             // Remove trailing dashes
}

export const toDataURL = url => fetch(url)
    .then(response => response.blob())
    .then(blob => new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onloadend = () => resolve(reader.result)
        reader.onerror = reject
        reader.readAsDataURL(blob)
    }))

export const notVirgul = (text) => {
    return text.replace(',', '')
}

export const splitF = (txt, len) => {
    if (txt) {
        if (txt.length < len) {
            return txt
        } else {
            return `${txt.slice(0, len)}...`
        }
    } else { 
        return "";
    }
}

export const dataURLtoFile = (dataurl, filename) => {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
}

export const randomColor = () => {
    const colors = ["#922b21", "#b03a2e", "#b03a2e", "#6c3483", "#1f618d", "#2874a6", "#148f77", "#1e8449", "#b7950b", "#b9770e", "#af601a", "#283747"]
    const randomIndex = Math.floor(Math.random() * colors.length);
    return colors[randomIndex];
}